import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function CopyrightComplaints() {

    return(
        <>
            <MainLayout title={'Copy Right Complaint'}>
                <section id='copy-right-complaint-section'>
                    <div className="container margin-top-container">
                            <p><strong>Copyright Infringement Procedure </strong></p>
                            <p>For complaints relating to copyright works protected by Canadian copyright laws, WorkX will process and investigate notices of alleged infringement and will take appropriate actions under the Canadian Copyright Modernization Act (<strong>“CCMA”</strong>) and other applicable intellectual property laws as outlined in Section (i) below.</p>
                            <p>For complaints relating to copyright works protected by US copyright laws, WorkX will process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act (<strong>“DMCA”</strong>) and other applicable intellectual property laws with respect to any alleged or actual infringement as outlined in Section (ii) below</p>
                                <p><strong>1. CCMA</strong></p>
                            <p>WorkX follows the CCMA’s notice-and-notice regime when receiving any  notice from a copyright holder or their agent claiming infringement of Canadian protected works. Under this regime, WorkX will forward that notice to the user accused of the infringement and will provide the copyright holder with confirmation of the notice’s delivery to that user.</p>
                            <p> If you are a copyright holder or an agent of a copyright holder and believe that any content displayed or made available through the Services infringes upon your copyright, you may submit a notice by providing our Copyright Agent the following information in writing by email, mail, or fax to WorkX’s Copyright Agent at the contact details at the bottom of this page (Subject line: “CCMA Copyright Request”):</p>
                            <ul className="wp-block-list" style={{listStyle:"disc"}} >
                                <li> a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work; </li>
                                <li> identification of the URL or other specific location on the Website where the material that you claim is infringing is located;  </li>
                                <li> your address, telephone number, and email address  </li>
                                <li> a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;  </li>
                                <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf; and</li>
                                <li> an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest.  </li>                    
                            </ul>
                            <p> <strong>2. DMCA</strong> </p>
                            <p>A notification of claimed copyright infringement of US protected works should be emailed, mailed, or faxed to WorkX’s Copyright Agent at the contact details at the bottom of this page (Subject line: “DMCA Takedown Request”). To be effective, the notification must be in writing and contain the following information:</p>
                            <ul className="wp-block-list" style={{listStyle:"disc"}}>
                                <li>a physical or electronic signature of a person authorized to act on behalf of the owner of the copyright or other intellectual property interest that is allegedly infringed;</li>
                                <li>identification of the copyrighted work or other intellectual property that you claim has been infringed, or, if multiple copyrighted works or other intellectual property are covered by a single notification, a representative list of such works or other intellectual property</li>
                                <li> identification of the content that is claimed to be infringing or to be the subject of infringing activity, and where the content that you claim is infringing is located on the Service, with enough detail that we may find it on the Service;  </li>
                                <li> your address, telephone number, and email address  </li>
                                <li> a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law; and </li>
                                <li>a statement by you that the information in your notice is accurate and, under penalty of perjury, that you are the copyright or intellectual property owner or are authorized to act on the behalf of the owner of the copyright or intellectual property that is allegedly infringed.</li>                    
                            </ul>
                            <p>If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to the Copyright Agent:</p>
                            <ul className="wp-block-list" style={{listStyle:"disc"}}>
                                    <li>your physical or electronic signature</li>
                                    <li>identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;
                                    </li>
                                    <li>a statement by you, made under penalty of perjury, that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content to be removed or disabled; and
                                    </li>
                                    <li>your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court located within the district of Delaware and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>                        
                            </ul>
                            <p>If a counter-notice is received by the Copyright Agent, WorkX will send a copy of the counter-notice to the original complaining party informing them that WorkX may replace the removed content or cease disabling it within ten (10) business days. Unless the owner of the applicable copyrighted work or other intellectual property files an action </p>
                            <p>seeking a court order against WorkX or the user, the removed content may be replaced, or access to it restored, within ten (10) to fourteen (14) business days or more after receipt of the counter-notice, at our sole discretion.</p>
                            <p>In accordance with the DMCA and other applicable law, WorkX has adopted a policy of terminating, in appropriate circumstances and at WorkX’s sole discretion, the accounts of users who are deemed to be repeat infringers. WorkX may also at its sole discretion limit access to the Service and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement. </p>
                            <p>We will respond to any copyright or other intellectual property infringement claims as soon as reasonably practicable.</p>
                            <p>Contact details for Copyright Agent</p>
                            <p>Email: <a href="mailto:legal@getWorkX.com" >legal@workxcrm.com</a></p>
                            <p>Phone: <a href="tel: +9999952777" >91-99999-52-777</a></p>
                            <p>Awapal Solutions Private Limited (d/b/a WorkX)</p>
                            <p>Attn: Privacy Officer<br/>B-4 First Floor, Sector 63 Noida<br/>U.P 201301, India</p>
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
