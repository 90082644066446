import React from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';

export default function SupplementalTermsFranchise() {

    return(
        <>
            <MainLayout title={'Supplemental Terms For Franchisees'}>
                <section id="supplemental-terms-franchise-section" className="wp-block-t3-single-column t3 line default-width t3-margin-top-sm t3-content" >
                    <div className="wp-block-t3-single-column__container t3-max-9-col content-align-center text-align-left" >
                        <p>These Supplemental Terms (the “Franchisee Terms”) are binding on any person or entity activating a WorkX account where the Services are:</p>    
                        <p>(i) purchased by the Distribution Partner (as defined below) on Your behalf; or</p>
                        <p>(ii) purchased by You as mandated by the Distribution Partner; or</p>
                        <p>(iii) You are invited by WorkX to set up a customised account under the Distribution Partner’s banner.</p>
                        <p>As used herein “You” or “Franchisee” means the person or entity creating an account to use the WorkX Service and “Distribution Partner” means an entity that has partnered with WorkX to set up a distribution relationship for its related entities, including entities related as franchisees and franchisors, or similar contractual relationships (for example, dealer and manufacturer relationship, multi-unit companies that may not be legally structured as franchises, etc.).</p>
                        <p>These Franchisee Terms are supplemental to the WorkX Terms of Service found at <Link legacyBehavior to="/legal/terms-of-service"><a>https://workxcrm.com/legal/terms-of-service</a></Link> (the “WorkX Terms of Service”) and Privacy Policy found at https://workxcrm.com/legal/privacy-policy/ which bind all users of the Website, App and Service. Capitalised terms not defined in these Franchisee Terms have the meanings given in the WorkX Terms of Service. </p>
                        <p>BY CREATING/CLAIMING AN ACCOUNT AS A FRANCHISEE YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS.  IN PARTICULAR, YOU ARE AWARE THAT DISTRIBUTION PARTNERS ARE GRANTED RIGHTS TO ADMINISTRATIVE PRIVILEGES AND ACCESS FOR ALL ACCOUNTS PURCHASED ON BEHALF OF OR BY FRANCHISEES AND WILL HAVE ACCESS TO ALL DATA IN SUCH ACCOUNTS AND THAT YOUR RIGHT TO ACCESS AND USE THE WorkX SERVICE IS ‘AT WILL’ AND SUBJECT TO THE DISTRIBUTION PARTNER’S ONGOING PROVISIONING OF SUCH ACCOUNT ACCESS ON YOUR BEHALF.</p>
                        <ul className="wp-block-list" >
                            <li>1. Entitlement.  Access to and use of the WorkX Service is for the WorkX Plan purchased.  Please see below for information on entitlement and changes to accounts</li>
                            <ul className="wp-block-list" >
                                <li>1. WorkX Plans Purchased by Distribution Partner.  WorkX Plans purchased by Distribution Partner may provide You with access to customizations, features, and rates not generally available as part of standard WorkX Plans.  Any WorkX Plan purchased on your behalf by a Distribution Partner cannot be altered without the Distribution Partner’s approval.  If Distribution Partner ceases to pay for Your subscription, fails to renew Your subscription, and/or requests that WorkX terminate Your access to the WorkX Service through an account purchased by Distribution Partner, we will terminate your access.  Please note that no Franchisee accounts  nor their information can be transferred to a new WorkX Plan.  For information about closed accounts and new accounts, please contact <Link legacyBehavior to="/help-center"><a>https://workxcrm.com/help-center</a></Link>.
                                </li>
                            </ul>
                            <li>2. WorkX Plans Purchased by Franchisee.  WorkX Plans purchased by You can be altered in accordance with the WorkX Terms of Services.  If You cease to pay for Your subscription, fail to renew Your subscription, and/or request that WorkX terminate Your access to the WorkX Service, we will terminate your access. We reserve the right to notify Your Distribution Partner of termination of any Franchisee accounts. 
                            </li>
                            <li>3. Account Ownership & Distribution Partner’s Rights.  The WorkX Terms of Service including the “Account Ownership” terms apply to all users of the WorkX Service including Franchisees; however, those terms are subject to certain rights of Distribution Partners as set out herein. Distribution Partners have certain rights to access Your account and administrator privileges. This includes, without limitation, the right to view and access all data and information contained in Your account, which is accessible to an administrator, including transaction history, details of administrators, account balances, and other information which you, as the Account Owner, will have access to. Distribution Partners also have the right to add users onto accounts at any time (and such users may be given administrative privileges by the Distribution Partner at their discretion), and includes the right to approve user access across multiple accounts within the Distribution Partner’s group of accounts as per the approved parties they have provided to WorkX.  Distribution Partners do not have the ability to enable WorkX Payments for your account.
                            </li>
                            <li>4. Term and Termination.   These Franchisee Terms are effective from the date You activate or claim a WorkX account as a Franchisee and will continue so long as You use the WorkX Service or until terminated by You, Distribution Partner, or by WorkX in accordance with the terms herein and the WorkX Terms of Service.
                            </li>
                            <li>5. Support/Disputes.   WorkX will use its commercially reasonable efforts to provide You with customer support to help resolve issues relating to Your arrangement with the Distribution Partner. However any disputes between You and Distribution Partner must be resolved directly between You and Distribution Partner and any requests for account shutdown by a Distribution Partner may be actioned by WorkX in WorkX’s discretion whether or not You have agreed to separate terms for account access with Your Distribution Partner.
                            </li>
                            <li>6. Release.  You hereby release and hold WorkX, its successors, assigns, affiliates, related companies, directors, officers, and employees (collectively the “Released  Parties”) harmless from all actions, causes of action, damages, claims, and demands whatsoever (including all damage, loss, and injury not now known or anticipated but which may arise in the future and all effects and consequences thereof), however and wherever arising in connection with the exercise of Distribution Partner rights in accordance with these Franchisee Terms, any rights of Distribution Partners which You have agreed to separately, and the exercise of any rights of WorkX as specified in these Franchisee Terms.   </li>
                            <li>7. Updates to these Terms.  These Franchisee Terms may be updated by WorkX from time to time in accordance with the terms set out in the WorkX Terms of Service.
                            </li>
                            <li>8. Contact. If you have any questions/concerns related to the franchisor/franchisee relationship and the WorkX Services, contact us at</li>
                            <li>Email: <a href="legal@getWorkX.com" >legal@workxcrm.com</a></li>
                    <li>Phone: <a href="tel:91-9999952777" >91-99999-52-777</a></li>
                    <p>Awapal Solutions Private Limited (d/b/a WorkX)</p>
                    <p>Attn: Privacy Officer<br/>B-4 First Floor, Sector 63 Noida<br/>U.P 201301, India</p>
                        </ul>
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
