import React from 'react';
import MainLayout from '../components/MainLayout';

export default function Integrations() {

    return(
        <>
            <MainLayout title={'Elevate Your Business with WorkX CRM Integrations & Apps'}>
                {/* Start tools integrations section */}
                <div className="home3-tools-section mb-110 mt-120" id="integration-section">
                    <div className="container">
                    <div className="row justify-content-center mb-60">
                        <div className="col-lg-8">
                        <div
                            className="section-title text-center wow animate fadeInDown"
                            data-wow-delay="200ms"
                            data-wow-duration="1500ms"
                        >
                            <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                            >
                                <g>
                                <circle cx={5} cy={5} r={5} />
                                </g>
                            </svg>
                            Connect Fast
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                            >
                                <g>
                                <circle cx={5} cy={5} r={5} />
                                </g>
                            </svg>
                            </span>
                            <h2>Integrate your favorite tools</h2>
                            <p>
                            Welcome to WorkX, where digital innovation meets strategic excellence. As a dynamic force in the realm of digital marketing, we are dedicated to propelling businesses into the spotlight of online success.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/google-local-services-icon.svg" alt="" />
                            </div>
                            <div className="content">
                            <h6>Google Local Services</h6>
                            <p>
                            Boost your local visibility by integrating with Google Local Services, connecting you with nearby customers searching for your services.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/quickbooks-icon.svg" alt="" />
                            </div>
                            <div className="content">
                            <h6>Quickbooks</h6>
                            <p>
                            Streamline your financial management by syncing with QuickBooks, ensuring accurate accounting, invoicing, and expense tracking.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/angi-icon.svg" alt="" />
                            </div>
                            <div className="content">
                            <h6>Angi Leads</h6>
                            <p>
                            Generate more leads by integrating with AngiLeads, helping you attract and convert potential customers efficiently.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/drive-icon.svg" alt="" />
                            </div>
                            <div className="content">
                            <h6>Google Drive</h6>
                            <p>
                            Enhance collaboration with Google Drive integration, allowing seamless file sharing and storage directly within your workflow.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/mailchimp-icon.svg" alt="" />
                            </div>
                            <div className="content">
                            <h6>Mailchimp</h6>
                            <p>
                            Improve your email marketing efforts by connecting with Mailchimp, making it easy to manage campaigns and target your audience.
                            </p>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="tools-card">
                            <div className="icon">
                            <img src="/assets/img/icon/brevo-icon.png" alt="" />
                            </div>
                            <div className="content">
                            <h6>Brevo</h6>
                            <p>
                            Optimize your communication strategies with Brevo, enabling effective email and SMS marketing directly from your platform.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End tools integrations section */}

                {/* Start new tool integration request section */}
                <div className="home3-newsletter-section mb-110">
                    <div className="container">
                    <div className="newsletter-wrapper">
                        <div className="row g-lg-4 gy-5 align-items-center">
                        <div
                            className="col-lg-7 col-md-6 wow animate fadeInLeft"
                            data-wow-delay="200ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="section-title white">
                            <span>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                >
                                <g>
                                    <circle cx={5} cy={5} r={5} />
                                </g>
                                </svg>
                                Looking for something?
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                >
                                <g>
                                    <circle cx={5} cy={5} r={5} />
                                </g>
                                </svg>
                            </span>
                            <h2>Request a new app that would make your work easier.</h2>
                            </div>
                        </div>
                        <div
                            className="col-lg-5 col-md-6 wow animate fadeInRight"
                            data-wow-delay="200ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="newsletter-form-area">
                            <h6>Request an App</h6>
                            <div className="form-inner">
                                <input type="email" placeholder="Email Address" />
                                <button type="submit">
                                <i className="bi bi-arrow-right" />
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End new tool integration request section */}
            </MainLayout>
        </>
    );
}
