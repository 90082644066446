import React from 'react';
import MainLayout from '../../components/MainLayout';
import { Link } from 'react-router-dom';

export default function PaymentTermsOfService() {

    return(
        <>
            <MainLayout title={'WorkXCRM Terms of Service - law enforcement & data disclosure requests policy'} >
                <section id="payment-terms-of-service-section">
                    <div className="container margin-top-container">
                        <ul className="wp-block-list">
                        <p>These terms and conditions (the “<strong>Payment Service Terms</strong>” or this <strong>“Agreement”</strong>) govern any and all use of the WorkX Payments Service. These terms are supplemental to the WorkX Terms of Service and Privacy Policy which bind all users of the Website, App, and Service. Capitalized terms not defined in these Payment Service Terms have the meanings given in the WorkX Terms of Service. </p>
                        <p><strong>1. Overview</strong></p>
                        <li>The WorkX Payments Service is a white-labelled payment solution which assists Users with accepting and processing payments from customers, and which is provided by a third party payment processor on behalf of WorkX as further described herein. WorkX is not a bank or other financial institution but is instead a supplier of the Service and the WorkX Payments Service as further described in these Payment Service Terms. By accepting this Agreement, you are also accepting and agreeing to be bound by the Processor Terms, which is the legal agreement between you and the Processor. The WorkX Payments Service is made available to Users in good standing, and at the sole discretion of WorkX at all times. WorkX reserves the right to collect certain additional information from Users from time to time in order to provide the WorkX Payments Service. You agree to provide all requested information to WorkX in a timely manner and acknowledge you may not be able to access or use the WorkX Payments Service until you provide such information. Fees for use of the WorkX Payments Service are set forth at <Link legacyBehavior to="https://workxcrm.com/legal/field-service-payments-collections"><a>https://workxcrm.com/legal/field-service-payments-collections</a></Link> and see further Fees in Section 10 below. </li>
                        <p> <strong>2. WorkX Payments Service Features. </strong> </p>
                        <li>a) If you register for or use the WorkX Payments Service, WorkX Payments will be able to process card payments through your WorkX account, process full refunds, and allow your end users to process payment electronically. WorkX Payments will also store and manage end user cards, permit you to view transactions, deliver automated receipts, and receive notification of payment disputes. You may also obtain point of sale equipment from WorkX, subject to availability, on the terms specified in Section 2(b) below entitled Terminals.</li>
                        <li>b) Terminals. If applicable, upon your purchase of point of sale terminal equipment from WorkX, you must agree to the applicable terminal purchase terms of the provider and use them within the country to which the provider initially shipped those products. In addition, the terms and conditions set out in the webstore from which you will obtain terminals will also apply to your purchase.</li>
                        <li>c) Business Purposes. The WorkX Payments Service may only be used for business purposes. You agree not to use the WorkX Payments Service for personal, household, or family purposes. </li>
                        <p><strong>3. Payment Processor.</strong></p>
                        <li>The WorkX Payments Service is currently provided by Stripe, as the payment processor (the <strong>“Processor”</strong>), on the terms of Stripe’s standard agreement(s) as further set out in Section 4 below (<strong>“Processor Terms”</strong>). WorkX reserves the right to change the payment processor, subject to the terms of our agreement with the Processor. In the event of any inconsistency between this Agreement and the Processor Terms, this Agreement shall prevail except in the event of any inconsistency between this Agreement and the Processor Terms concerning payment processing or your account with the Processor, in which case the Processor Terms shall prevail.</li>
                        <p><strong>4. Processor Terms and Other Third Party Terms. </strong></p>
                        <li>a) IN ORDER TO USE THE WorkX PAYMENTS SERVICE PROVIDED BY STRIPE, YOU MUST FIRST AGREE TO THE STRIPE CONNECTED ACCOUNT AGREEMENT AND THE STRIPE SERVICES AGREEMENT APPLICABLE IN YOUR JURISDICTION, AND ANY OTHER APPLICABLE STRIPE TERMS INDICTED THEREIN (<strong>“STRIPE TERMS”</strong>), ALL OF WHICH ARE HEREBY INCORPORATED BY REFERENCE INTO THESE PAYMENT SERVICE TERMS. BY ORDERING, REGISTERING FOR, OR USING THE WorkX PAYMENTS SERVICE, YOU HEREBY REPRESENT AND WARRANT THAT YOU HAVE READ THE STRIPE TERMS AND AGREE TO BE BOUND BY THEM.</li>
                        <li>b) In order to use Stripe terminals, you also agree to the Stripe Terminal Purchase Terms and Stripe Terminal Terms and use Stripe Terminal Products (as defined therein) only within the country to which Stripe initially shipped those products. In addition, purchases of terminals through the WorkX sales page on Shopify are subject to the terms of sale set out on that site. You acknowledge and agree that use of the Processor’s services and equipment and the applicable Processor Terms are between the Processor and you, and WorkX shall not have any liability or responsibility for any breach of the Processor Terms, or any losses, damages, expenses costs or other injuries incurred by you in connection with such agreements or the acts or omissions of Processor.      </li>            
                        <li>c) Additionally, in order to provide the WorkX Payments Service, we may use a variety of third party sub-processors—for example, we use sub-processors to help us: (i) with internal due diligence; (ii) protect you and WorkX from potentially risky transactions, security threats, or fraud; (iii) perform administrative tasks; (iv) develop and improve our products and the WorkX Payments Service; (v) generate analytics or other information relating to the Payments Service; and, (vi) build our technical infrastructure. By using the Payments Service, you consent to our use of sub-processors, which is described in more detail in our Privacy Policy. </li>
                        <li>d) WorkX currently uses a third party provider, LexisNexis, to conduct internal risk assessments as part of our internal due diligence process. IN ORDER TO USE THE WorkX PAYMENTS SERVICE, YOU MUST FIRST AGREE TO LEXISNEXIS’ APPLICABLE PRIVACY POLICY AND ANY OTHER APPLICABLE TERMS INDICTED THEREIN (<strong>“LEXISNEXIS TERMS”</strong>), ALL OF WHICH ARE HEREBY INCORPORATED BY REFERENCE INTO THESE PAYMENT SERVICE TERMS. BY ORDERING, REGISTERING FOR, OR USING THE WorkX PAYMENTS SERVICE, YOU HEREBY REPRESENT AND WARRANT THAT YOU HAVE READ THE LEXISNEXIS TERMS AND AGREE TO BE BOUND BY THEM.</li>
                        <p><strong>5. Registration & Payment Information.</strong></p>
                        <li>a) Accuracy of Information. As between you and WorkX, you are responsible for all the information you provide in connection with registering for and using the WorkX Payments Service. You hereby represent and warrant that you are providing true, accurate, and complete information to WorkX, and shall fully indemnify WorkX for any losses, costs, or expenses incurred by WorkX or any third party arising as a result of your failure to provide accurate, timely, and complete information.</li>
                        <li>b) User Authorization. If you subscribe to use, or in any other way enable directly or indirectly, the WorkX Payments Service, you hereby authorize WorkX to collect certain payment information and data (including account and transaction data, and certain personal information about your end users), and to store, use, and process such information data for the purposes of providing the WorkX Payments Service.</li>
                        <li>c) Customer Consents. It is your responsibility to obtain your customers’ consent to be billed for each transaction or, as the case may be, on a recurring basis, in compliance with applicable legal requirements and the requirements of Visa, MasterCard, American Express, or other applicable card networks. For greater certainty, you acknowledge and agree that it is your responsibility to ensure that you comply with all applicable rules, guidelines, and terms of use of any applicable card network.</li>
                        <li>d) External Deposit Account. You must provide WorkX with the account information for an external deposit account owned by you in order to use the WorkX Payments Service. You agree to notify us of any changes to your external deposit account immediately. </li>
                        <p><strong>6. User Covenants. </strong></p>
                        <li>You are required to obey all laws, rules, and regulations applicable to your use of the WorkX Payments Service. You hereby covenant, represent, and warrant that you will not use the WorkX Payments Service for any personal, illegal, deceptive, or fraudulent activity, including for any prohibited businesses, including, without limitation, any activities set out on the Stripe Prohibited Business list found here: https://stripe.com/ca/restricted-businesses. WorkX hereby reserves the right, in its sole discretion, at any time, to suspend or terminate your account if we have a good faith reason to believe that anyone is using your account or access and have engaged in any fraudulent or illegal activities, or are engaging in prohibited business activity, and to  provide any relevant information to the applicable authorities in connection with any investigation of the same.  </li>
                        <p><strong>7. Authorization to Charge Payment Methods on File.</strong></p>
                        <li>You authorize us to charge any payment method we have on file for you, including your external deposit account, in the amount of any Losses we may incur related to any transaction you may enter into through, from, or adjacent to the Service, to fund the Reserve Account, or any other amount you owe us. To execute such charges, you expressly authorize us to (a) initiate individual or recurring debit entries to any bank account we have on file for you (e.g., ACH debits), and (b) charge any credit or debit card account we have on file for you. If any debit entry or charge is rejected, you agree to immediately pay the amount due directly to us and authorize us to initiate other debit entries and charges at any time and from time to time in any amount up to the amount due until the amount due is paid in full. We are not responsible for any amount you may incur from our charges, including overdraft and overlimit fees. Payment methods we have on file for you include payment methods you identify to us and payment methods we identify on our own. For purposes of this and the next section, “Losses” include chargebacks, refunds, returns, shortfalls, adjustments, settlements, losses, liabilities, damages, fines, penalties, fees, costs, and expenses.</li>
                        <p><strong>8. Reserve Accounts; Personal Guarantee.</strong></p>
                        <li>a) Reserve Account. WorkX may withhold funds from payouts (including Processor payouts) to you and/or require that certain sums of money be set aside and held in a reserve account to cover potential or actual Losses we believe we may incur related to your transactions, your use of the Service, your business, or to secure the performance of your obligations under any agreement between you and us, including this Agreement (a <strong>“Reserve Account“</strong>). WorkX will set the terms of your Reserve Account and notify you of such terms, which may require that a certain amount (including the full amount) of the funds received for a transaction processed through the WorkX Payments Service are held for a period of time, or that additional amounts are held in the Reserve Account. WorkX reserves the right to change the terms of the Reserve Account at any time, for any reason, including for reasons based on your payment processing history, WorkX’s own internal or external risk assessments, or as requested by the Processor. 
                    We may require you to fund the Reserve Account by means of</li>
                            <ul className="wp-block-list" >
                                <li>(i) any funds payouts made or due to you for transactions submitted to the WorkX Payments Service;</li>
                                <li>(ii) other sources of funds associated with your WorkX account; or</li>
                                <li>(iii) requesting that you provide funds to us for deposit to the Reserve Account. If you do not have sufficient funds in your Reserve Account, we may fund the Reserve Account from any payment method we have on file for you. You grant us a security interest in and lien on any and all funds held in the Reserve Account, and also authorize us to make any withdrawals or debits from the Reserve Account, and charge any payment method we have on file for you, to cover any Losses, obligations, and amounts you owe us. You will execute any documents required by us to perfect our security interest in any funds in the Reserve Account or requested by us in connection with the Reserve Account. You acknowledge and agree that: </li>
                                <li>(iv) you are not entitled to any interest or other compensation associated with funds held in the Reserve Account; </li>
                                <li>(v) you have no right to direct the Reserve Account;</li>
                                <li>(vi) you have no legal interest in those funds or the Reserve Account; and</li>
                                <li> (vii) you may not assign any interest in those funds or the Reserve Account.</li>
                                <li>b) Personal Guarantee. WorkX may require you to provide a personal or company guarantee for amounts owed under the WorkX Payment Service Terms. We will notify you if we require you to provide a guarantee, and you will not be permitted to access the WorkX Payments Service prior to providing such guarantee.
                                </li>
                            </ul>
                            <p><strong>9. Payment-Related Issues.</strong></p>
                            <li><strong>a) Chargebacks; Account Balance Issues & Refund Functionality.</strong></li>
                            <li>A chargeback is a disputed charge raised by a customer which is raised with their credit card company and can be on a variety of grounds including contesting a transaction as fraudulent, unrecognized or a duplicate charge, or in relation to non-receipt of products or services or poor quality of the same. You are solely responsible for all reversed or charged back transactions, including any processing fees levied by the card company, with your customers regardless of the reason for, or timing of, the reversal or chargeback and have primary responsibility for responding to and contesting (if applicable) any chargeback in respect of a transaction to which you are a party. You can do this by contacting the applicable customer and/or submitting evidence to validate a transaction directly with the customer’s card provider.  </li>
                            <li>In the event that you do not promptly address a chargeback, you hereby grant WorkX the ongoing right and authorization to collect and submit, in its sole discretion, relevant information and evidence from your WorkX account to the applicable card company in order to address any disputed charge.  
                            </li>
                            <li><strong>b) Negative Account Balances & Repayment Plans.</strong></li>
                            <li>For any WorkX account which has a negative balance (e.g., you provide more refunds than you receive payments and/or have a number of disputed transactions), that negative balance constitutes a debt owed to WorkX. Accordingly, the following terms apply:</li>
                            <li>i) any payments received to that account (e.g., from future customer transactions) will be applied to automatically reduce the balance;  </li>
                            <li>  ii) the functionality which enables the processing of refunds through the affected account may be disabled at any time by WorkX without prior notice; and  </li>
                            <li>  iii) you may be required by WorkX, in WorkX’s sole discretion, to address any negative balance through a repayment plan agreement. You acknowledge and agree that it is a condition of your being granted rights to use WorkX Payments Service that you will enter into a repayment plan agreement with WorkX if such a plan is deemed necessary by      </li>
                            <li>WorkX. If you do not enter into such a plan or fail to meet the repayment schedule in the applicable repayment plan, WorkX may terminate your account or take any other actions deemed appropriate by WorkX to the extent permitted by law.
                            </li>
                            <li><strong>c) Payment Disputes. </strong></li>
                            <li>  As between you and your end users, all payment disputes between you and your customers arising in connection with your use of the WorkX Payments Service are between you and such end users, and not you and WorkX or WorkX and your end users. WorkX shall not be liable or responsible in any way for any payment dispute between you and any third party that uses the WorkX Payments Service feature to process payments. You hereby agree to indemnify, defend, and hold WorkX harmless for any costs, damages, claims, losses, expenses, or liabilities incurred by WorkX in connection with any payment dispute arising between you and any end user or other third party.</li>
                            <p> <strong>10. WorkX Limitation of Liability. </strong> </p>
                            <li> The WorkX Payments Service is provided ‘as is’ and ‘as available’ without any warranties, either express, implied, or statutory. Use of the WorkX Payments Service is at your own risk. In addition to the limitations of liability set out in the WorkX Terms of Service in connection with use of the Website, App, and/or Service, because the WorkX Payments Service is provided and hosted by a third party service provider, we are not responsible for, and expressly disclaim all damages, whether direct and indirect, incidental, consequential, special, or other damages in connection with:  </li>
                            <li>(a) any technical issues, errors, unavailability or failures, losses, damages, loss of data, lost profits, or other losses or claims originating from or arising in connection with the Processor’s platform;     </li>
                            <li>(b) any unauthorized access, hacking, or tampering with or to your account or the services provided by the Processor by third parties; or  </li>
                            <li>(c) any software, bugs, viruses, Trojan horses, or other harmful or malicious code that may be transmitted via the Processor’s platform.</li>
                            <li>In certain circumstances detailed in the Processor Terms, the Processor may withhold or delay processing transfers and transactions and WorkX shall not be responsible in any way for any such action taken by the Processor. In addition, you specifically acknowledge that WorkX cannot ensure that your customers will complete a transaction or are authorized to do so.</li>
                            <p><strong>11. Fees & Taxes.</strong></p>
                            <li>a) Transaction & Processing Fees. You agree to pay the applicable fees for processing which apply to the processing of any transactions using the WorkX Payments Service.      </li>
                            <li>b) Terminal Fees. Fees for Terminals are set out here:  <Link legacyBehavior to="/legal/payment-service-fees"><a>https://workxcrm.com/payment-service-fees</a></Link> </li>
                            <li>c) Service Fees. For clarity, fees for the Service are collected by WorkX pursuant to the WorkX Terms of Service.</li>
                            <li>d) Taxes. You are obligated to pay all applicable taxes, fees, and other charges imposed by any governmental authority, including, without limitation, any value added tax, goods and services tax, harmonized sales tax, and/or provincial or territorial sales tax on the WorkX Payments Service. If you are tax-exempt, you will provide us with an appropriate certificate or other evidence of tax exemption that is satisfactory to us.
                            </li>
                            <li>e) Fee Increases. We reserve the right to revise our fees at any time and revised fees will take effect fourteen (14) days after notice of the increase has been posted on the Website. If you continue to use the WorkX Payments Service, then you are deemed to have accepted the change in fees contemplated by such notice.
                            </li>
                            <li>f) Penalties and Fines. In addition to the fees, you are also responsible for any penalties and fines imposed on you or on us by any bank, money services business, payment network, financial institution, Processor, or other financial intermediary resulting from your use of the WorkX Payments Service in a manner not permitted by this Agreement or by such financial intermediary’s rules and regulations.
                            </li>
                            <li>g) Form 1099-K. Unless prohibited by applicable law, you acknowledge and agree that any 1099-Ks may be transmitted to you electronically and may be filed by WorkX, if required, with the IRS.
                            </li>
                            <p><strong>12. Term and Termination. </strong></p>
                            <li>a) Term. These Payment Service Terms are effective upon the date you agree to it (by electronically indicating acceptance) and continue so long as you use the WorkX Payments Service or until terminated by you or by WorkX in accordance with Section 12(b) below.</li>
                            <li>b) Termination. You may terminate this Agreement by closing your WorkX Payments Account at any time by following the instructions in your WorkX Admin panel. We may terminate this Agreement and close your WorkX Payments Account at any time, for any reason, upon notice to you. We may suspend your WorkX Payments Account and your access to the WorkX Payments Service and any rights in respect of your WorkX Payments Account, or terminate this Agreement, at any time, for any reason, including if: </li>
                            <ul className="wp-block-list" >
                                <li>(i) we determine that you may be ineligible for the WorkX Payments Service because of the risk associated with your WorkX Payments Account, including, without limitation, significant credit or fraud risk, or for any other reason;</li>
                                <li> (ii) you do not comply with any of the provisions of this Agreement or the Processor Terms; or </li>
                                <li> (iii) upon request of the payment network, the Processor, or a card issuer. Termination of the Processor Terms may, at the discretion of WorkX, result in a termination of this Agreement. Termination of this Agreement shall entitle WorkX to cause the Processor to terminate the Processor Terms.
                                </li>                
                            </ul>
                            <li>If the Processor terminates the Processor Terms or indicates its intention to do so, or if you elect to cease processing with such Processor, we have the right, but not the obligation, to offer you a substitute payment processor that is integrated with your WorkX Payments Account. Upon your acceptance of the terms of service of such  substitute payment processor, they shall be deemed to have replaced the Processor contemplated herein, provided that your liabilities to the Processor herein shall not be diminished on account of accepting the terms of the substitute payment processor.
                            </li>
                            <li>c) Effects of Termination. Upon termination and closing of your WorkX Payments Account, we will immediately discontinue your access to the WorkX Payments Service. You agree to complete all pending transactions, immediately remove all logos for cards, and stop accepting new transactions through the WorkX Payments Service. You will not be refunded the remainder of any fees that you have paid for the WorkX Payments Service if your access to or use of the WorkX Payments Service is terminated or suspended. Any funds in the Processor’s custody will be paid out to you subject to the terms of your Payout Schedule (as defined in the Processor Terms).
                            </li>
                            <li>Termination does not relieve you of your obligations as defined in this Agreement, and the Processor may elect to continue to hold any funds deemed necessary, pending resolution of any other terms or obligations defined in this Agreement, including, but not limited to, chargebacks, fees, refunds, or other investigations or proceedings.
                            </li>
                            <li>Termination of this Agreement will not necessarily terminate your account on the Website for the Service, unless WorkX determines otherwise.Upon termination you agree
                            </li>
                            <ul className="wp-block-list">
                                <li>(i) to immediately cease your use of the WorkX Payments Service;</li>
                                <li>(ii) that the license granted under this Agreement shall end</li>
                                <li> (iii) that we reserve the right (but have no obligation) to delete all of your information and account data stored on our servers;</li>
                                <li> (iv) that we will not be liable to you for compensation, reimbursement, or damages in connection with your use of the WorkX Payments Service, or any termination or suspension of the WorkX Payments Service, or deletion of your information or account data; and </li>
                                <li>(v) that you will still be liable to us for any fees or fines, or other financial obligation incurred by you or through your use of the WorkX Payments Service prior to termination.
                                </li>
                            </ul>
                            <p><strong>13. Customer Service.</strong></p>
                            <li>WorkX will use its commercially reasonable efforts to provide you with customer support to help resolve issues relating to the WorkX Payments Service. The Processor retains sole and exclusive responsibility for payment processing of transactions, including the settlement of funds, but WorkX will provide reasonable assistance in liaising between you and the Processor concerning the payment processing services. You assume sole and exclusive responsibility for providing customer service or support to your customers for any and all issues related to your products and services, including, but not limited to, issues arising from the processing of cards through the WorkX Payments Service.
                            </li>
                            <p><strong>14. Updates to these Payment Service Terms.  </strong></p>
                            <li>These Payment Service Terms may be updated by WorkX from time to time in accordance with the terms on updates set out in the WorkX Terms of Service.  
                            </li>
                        </ul>


                    </div>
                </section>
            </MainLayout>
        </>
    );
}
