import React from 'react';
import MainLayout from '../components/MainLayout';

export default function Industries() {

    return(
        <>
            <MainLayout title={'WorkX CRM: Tailored Solutions for Diverse Industries | Enhance Your Business Efficiency'}>
                {/* Start Industry Card Section */}
                <div
                    className="industry-card-section scroll-margin pt-120 mb-120"
                    id="industry-card-section"
                >
                    <div className="container">
                    <div className="row g-4">
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-01.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Electrical</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-02.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>HVAC </h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-03.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Landscaping</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="800ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-04.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Pest Control</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="800ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-05.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Roofing</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-06.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Handyman Services</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-07.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Pool Cleaning</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-08.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Security Systems</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-09.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Plumbing</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="800ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-10.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Appliance Repair</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="800ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-11.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Cleaning</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-12.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Pool Service</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-13.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Painting</h5>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                            <img src="/assets/img/innerpage/industries/industry-14.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                            <h5>Construction</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-15.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Elevator Services</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-16.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Pool Spa Services</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-17.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Window Cleaning</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-18.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Carpentry</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-19.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Tree Care</h5>
                            </div>
                        </div>
                        </div>
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                        >
                        <div className="industry-card">
                            <div className="industry-img">
                                <img src="/assets/img/innerpage/industries/industry-20.jpg" alt="" />
                            </div>
                            <div className="industry-content">
                                <h5>Airconditioning</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Industry Card Section */}
            </MainLayout>
        </>
    );
}
