import React from 'react';
import PhoneInput from 'react-phone-input-2';

export default function CustomModal(
    { isOpen,onClose,children }
) {
    return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }} onClick={onClose}>
          <div style={{
            background: 'white',
            borderRadius: "8px",
            padding: "20px",
            maxWidth: "600px",
            width: "100%",
            maxHeight: "90vh",
            overflowY: 'auto',
            position: 'relative',
          }} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>,
        document.body
      );
}
