import React, { useEffect, useState } from 'react';
import API from '../apis';
import { Link } from 'react-router-dom';

export default function PricingComponent() {

    let [duration, setDuration] = useState([
        {id: 1, name: "Monthly", tabId: "nav-monthly-tab", targetId: "nav-monthly", isActive: ''},
        {id: 2, name: "Quaterly", tabId: "nav-quaterly-tab", targetId: "nav-quaterly", isActive: 'active'},
        {id: 3, name: "Half-Yearly", tabId: "nav-half-yearly-tab", targetId: "nav-half-yearly", isActive: ''},
        {id: 4, name: "Yearly", tabId: "nav-yearly-tab", targetId: "nav-yearly", isActive: ''}
    ]);
    const [trialPackageList, setTrialPackageList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [featureCategoryList, setFeatureCategoryList] = useState([]);

    useEffect(() => {
        getTrialPackagePriceListAPI();
        getPackagePriceListAPI();
        getFeatureCategoryListAPI();
    }, [])

    // For All Trail Package List -------------------------------
    // 
    // const trialPackageListURL = "https://api.workxcrm.com/api/subscription/getSubscriptionPackageLst?isTrail=true";
    // const getTrialPackagePriceListAPI = () => {
    //     API.getRequestWithJsonForOnline(trialPackageListURL, '').then(response => {
    //         if (response.result.response_Status == 'success') {
    //             setTrialPackageList(response.result.returnClass);
    //         }
    //         else {
    //             alert('Error - ', response.result.response_Message);
    //         }
    //     })
    // };
    // const getFilterTrialPackageId = (subscriptionName) => {
    //     return trialPackageList.filter(e => e.subscriptionName.toString() === subscriptionName.toString())[0]?.pk_SubscriptionPackId;
    // }

    const trialPackageListURL = "https://api.workxcrm.com/api/subscription/getsubscriptionPackagePrice?isTrial=true";
    const getTrialPackagePriceListAPI = () => {
        API.getRequestWithJsonForOnline(trialPackageListURL, '').then(response => {
            if (response.result.response_Status == 'success') {
                setTrialPackageList(response.result.returnClass);
            }
            else {
                alert('Error - ', response.result.response_Message);
            }
        })
    };
    const getFilterTrialPackageId = (packageType_Name) => {
        return trialPackageList.filter(e => e.packageType_Name.toString() === packageType_Name.toString())[0]?.pk_PackagePriceId;
    }

    const packageListURL = "https://api.workxcrm.com/api/subscription/getsubscriptionPackagePrice?isSubscribe=true";
    const getPackagePriceListAPI = () => {
        API.getRequestWithJsonForOnline(packageListURL, '').then(response => {
            if (response.result.response_Status == 'success') {
                setPackageList(response.result.returnClass);
            }
            else {
                alert('Error - ', response.result.response_Message);
            }
        })
    };
    const getFilterPckgListData = ({durationId}) => {
        return packageList.filter(e => e.fk_durationTypeId === durationId).sort(function(a,b){return a.pk_PackagePriceId - b.pk_PackagePriceId});
    };

    const featureLstURL = "https://api.workxcrm.com/api/subscription/getSubscriptionFeatureLst";
    const getFeatureCategoryListAPI = () => {
        API.getRequestWithJsonForOnline(featureLstURL, '').then(response => {
            if (response.result.response_Status == 'success') {
                setFeatureCategoryList(response.result.returnClass);
            }
            else {
                alert('Error - ', response.result.response_Message);
            }
        })
    };
    const getFeatureCategoryListByPackageId = (pckgId) => {
        const filterFeatureCategoryByPackageId = featureCategoryList.filter(e => e.fk_SubscriptionPackId == pckgId);
        const filterFeatureCategoryList = [...new Map(filterFeatureCategoryByPackageId.map(item => [item['fk_FeatureCategoryId'], item])).values()];
        console.log('filterFeatureCategoryList - ', filterFeatureCategoryList);
        return filterFeatureCategoryList;
    }

    return(
        <>
          <div
            className="home2-pricing-plan-section  pt-120 mb-120"
            id="pricing-plan-section"
        >
            <div className="container">
                <div className="pricing-plan-tab-area">

                {/* duration type buttons */}
                <nav> 
                    <div className="nav nav-tabs" id="nav-tab2" role="tablist">
                        {duration.map((durationObj) => (
                            <>
                            {getFilterPckgListData({durationId: durationObj.id}).length > 0 && (
                                <button 
                                    className={`nav-link ${durationObj.isActive}`} 
                                    id={`${durationObj.tabId}`} 
                                    key={durationObj.id}
                                    data-bs-toggle="tab" 
                                    data-bs-target={`#${durationObj.targetId}`} 
                                    type="button" 
                                    role="tab" 
                                    aria-controls="nav-home" 
                                    aria-selected="true">
                                    {durationObj.name}
                                </button>
                            )}
                            </>
                        ))} 
                    </div>
                </nav>

                <div className="tab-content" id="nav-tab2Content">
                    {duration.map((durationObj) => (
                        <div className={`tab-pane fade show ${durationObj.isActive}`} id={`${durationObj.targetId}`} role="tabpanel" aria-labelledby={`${durationObj.tabId}`} tabIndex={0}>
                        <div className="row g-xl-3 gy-4 justify-content-center">
                                {(getFilterPckgListData({durationId: durationObj.id})).map((obj, index) => (
                                <div className="col-xl-4 col-md-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                    <div className={`pricing-card ${((index+1)%2 == 0) ? 'two' : ''}`}>
                                        <div className="pricing-top">
                                        <span>{obj.subscriptionName}</span>
                                        <h2>₹{obj.packagePayblePriceAmt}<sub>/ Month / Billed {durationObj.name}</sub></h2>
                                        </div>
                                        <div className="pricing-content">
                                            {getFeatureCategoryListByPackageId(obj.fk_SubscriptionPackId).length > 0 && (
                                                <ul key={index}>
                                                    {getFeatureCategoryListByPackageId(obj.fk_SubscriptionPackId).map((featureObj, featureIndex) => (
                                                    <li key={featureObj.pk_SubsFeatureMapId}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" key={`${featureIndex}`}>
                                                            <g>
                                                            <path d="M16 8C16 8.68267 15.1613 9.24533 14.9933 9.87467C14.82 10.5253 15.2587 11.432 14.9293 12.0013C14.5947 12.58 13.588 12.6493 13.1187 13.1187C12.6493 13.588 12.58 14.5947 12.0013 14.9293C11.432 15.2587 10.5253 14.82 9.87467 14.9933C9.24533 15.1613 8.68267 16 8 16C7.31733 16 6.75467 15.1613 6.12533 14.9933C5.47467 14.82 4.568 15.2587 3.99867 14.9293C3.42 14.5947 3.35067 13.588 2.88133 13.1187C2.412 12.6493 1.40533 12.58 1.07067 12.0013C0.741333 11.432 1.18 10.5253 1.00667 9.87467C0.838667 9.24533 0 8.68267 0 8C0 7.31733 0.838667 6.75467 1.00667 6.12533C1.18 5.47467 0.741333 4.568 1.07067 3.99867C1.40533 3.42 2.412 3.35067 2.88133 2.88133C3.35067 2.412 3.42 1.40533 3.99867 1.07067C4.568 0.741333 5.47467 1.18 6.12533 1.00667C6.75467 0.838667 7.31733 0 8 0C8.68267 0 9.24533 0.838667 9.87467 1.00667C10.5253 1.18 11.432 0.741333 12.0013 1.07067C12.58 1.40533 12.6493 2.412 13.1187 2.88133C13.588 3.35067 14.5947 3.42 14.9293 3.99867C15.2587 4.568 14.82 5.47467 14.9933 6.12533C15.1613 6.75467 16 7.31733 16 8Z" />
                                                            <path d="M10.312 5.61587L7.26668 8.6612L5.68802 7.08387C5.52338 6.91932 5.30013 6.82688 5.06735 6.82688C4.83458 6.82688 4.61133 6.91932 4.44668 7.08387C4.28213 7.24851 4.1897 7.47176 4.1897 7.70454C4.1897 7.93731 4.28213 8.16056 4.44668 8.3252L6.66135 10.5399C6.8216 10.6999 7.03885 10.7899 7.26535 10.7899C7.49186 10.7899 7.7091 10.6999 7.86935 10.5399L11.552 6.8572C11.7166 6.69256 11.809 6.46931 11.809 6.23654C11.809 6.00376 11.7166 5.78051 11.552 5.61587C11.4706 5.5344 11.374 5.46977 11.2676 5.42568C11.1612 5.38158 11.0472 5.35889 10.932 5.35889C10.8169 5.35889 10.7028 5.38158 10.5964 5.42568C10.4901 5.46977 10.3934 5.5344 10.312 5.61587Z" />
                                                            </g>
                                                        </svg>
                                                        {featureObj.categoryDescription}
                                                    </li>
                                                    ))}
                                                </ul>
                                            )}
                                            <div className="pay-btn-area" key={`${obj.fk_SubscriptionPackId}-${index}`}>
                                                <Link
                                                    to='/registration'
                                                    // state={{ packageData: getFilterTrialPackageId(obj.subscriptionName) }}
                                                    state={{
                                                        packageData: {
                                                            pckgId: getFilterTrialPackageId('Trial Pack'),
                                                            packageName: obj.subscriptionName,
                                                            packagePrice: obj.packagePayblePriceAmt,
                                                        },
                                                    }}
                                                    className="pay-btn"
                                                    data-text="Get 30 Days Free Trial"
                                                    >
                                                    <span>Get 30 Days Free Trial</span>
                                                </Link>
                                            </div>
                                            {obj.discountPercent > 0 && (
                                                <div className="batch">
                                                    <span>
                                                        <strong>{obj.discountPercent}%</strong> Off
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </div>  
        </>
    );
}
