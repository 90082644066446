import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CookieSettings from './legal/cookie-settings';
import PrivacyPolicy from './legal/privacy-policy';
import Security from './legal/security';
import AccountCreationfraud from './legal/account-security-and-fraud-prevention';
import CopyrightComplaints from './legal/copyright-complaints';
import GdprPrivacy from './legal/gdpr';
import LawEnforcementData from './legal/law-enforcement-data-disclosure-requests-policy';
import PartnerProgramTermsConditions from './legal/partner-program-terms-and-conditions';
import PaymentServiceFees from './legal/payment-service-fees';
import PaymentTermsOfService from './legal/payments-terms-of-service';
import Subprocessors from './legal/subprocessors';
import SupplementalTermsFranchise from './legal/supplemental-terms-for-franchisees';
import TermsOfService from './legal/terms-of-service';

function LegalPages() {
    return (
        <>
        <div>
            <Routes>
                <Route path='cookie-settings' element={<CookieSettings/>} />
                <Route path='privacy-policy' element={<PrivacyPolicy/>} />
                <Route path='security' element={<Security/>} />
                <Route path='terms-of-service' element={<TermsOfService/>} />
                <Route path='account-security-and-fraud-prevention' element={<AccountCreationfraud/>} />
                <Route path='copyright-complaints' element={<CopyrightComplaints/>} />
                <Route path='gdpr' element={<GdprPrivacy/>} />
                <Route path='law-enforcement-data-disclosure-requests-policy' element={<LawEnforcementData/>} />
                <Route path='partner-program-terms-and-conditions' element={<PartnerProgramTermsConditions/>} />
                <Route path='payment-service-fees' element={<PaymentServiceFees/>} />
                <Route path='payment-terms-of-service' element={<PaymentTermsOfService/>} />
                <Route path='subprocessors' element={<Subprocessors/>} />
                <Route path='supplemental-terms-for-franchisees' element={<SupplementalTermsFranchise/>} />
            </Routes>
        </div>
        </>
    );
}

export default LegalPages;