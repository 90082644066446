import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function Subprocessors() {

    return(
        <>
            <MainLayout title={'Subprocessors'}>
                <section id="subprocessors-section" className="wp-block-t3-single-column t3 line default-width t3-margin-top-sm t3-content"  >
                    <div className="wp-block-t3-single-column__container t3-max-9-col content-align-center text-align-left" >
                        <ul className="wp-block-list"> 
                            <p>To support WorkX in delivering its Service, WorkX may engage third party service providers to assist WorkX with its data processing activities. When we work with these service providers in our capacity as a data processor, the third-party service provider is a sub-processor of WorkX (“Sub-processor”).</p>
                            <p><strong>Last updated: February 29, 2024</strong></p>
                        </ul>
                        <ul className="wp-block-list">
                            <table id="subprocessor-outline-table">
                                <thead>
                                    <tr id="subprocessor-tr">
                                        <th>Subprocessor</th>
                                        <th>Service Provided</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Amazon Web Services Inc.</td>
                                        <td>Cloud hosting (Analytics, compute, database, machine learning, networking, and storage)</td>
                                        <td>USA</td>
                                    </tr>

                                    <tr>
                                        <td>Autopilot</td>
                                        <td>Cloud-based Email Delivery Services</td>
                                        <td>USA</td>
                                    </tr>

                                    <tr>
                                        <td>Ekata †</td>
                                        <td>Identity verification</td>
                                        <td>USA, EU</td>
                                    </tr>

                                    <tr>
                                        <td>Google LLC</td>
                                        <td>Cloud hosting (Analytics, compute, database, machine learning, networking, and storage); Mapping delivery; Google Local Services; Data import from Google Services into WorkX *</td>
                                        <td>USA</td>
                                    </tr>

                                    <tr>
                                        <td>OpenAI</td>
                                        <td>AI Functionality</td>
                                        <td>USA</td>
                                    </tr>

                                    <tr>
                                        <td>Sift</td>
                                        <td>Fraud detection and analysis</td>
                                        <td>USA</td>
                                    </tr>


                                    <tr>
                                        <td>Stripe Inc. †</td>
                                        <td>Payment processing and other financial services</td>
                                        <td>USA</td>
                                    </tr>

                                    <tr>
                                        <td>Twilio Inc. (including Sendgrid)</td>
                                        <td>External communications provider including email and SMS</td>
                                        <td>USA</td>
                                    </tr>

                                </tbody>
                            </table>
                        </ul>
                        <ul className="wp-block-list" >
                            <p>† These services are tied to use of the WorkX Payments Service</p>
                            <p>* These services are tied to optional, opt-in features within WorkX</p>
                        </ul>
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
