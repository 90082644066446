import React from 'react';
import MainLayout from '../components/MainLayout';

export default function Faqs() {

    return(
        <>
          <MainLayout title={'WorkX CRM| Free Trial, Billing & Account FAQs'}>
                <div className="faq-section scroll-margin pt-120 mb-120" id="faq-section">
                    <div className="container">
                        <div className="row g-4 mb-120">
                            <div className="col-lg-4 d-flex justify-content-lg-center align-items-lg-center">
                            <div className="verticle-text">
                                <h2>Trail</h2>
                            </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="faq-content style-2">
                                    <div className="accordion" id="accordionGeneral">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseOne"
                                            aria-expanded="true"
                                            aria-controls="faqcollapseOne"
                                        >
                                            01. How do I sign up for your SaaS product?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="faqheadingOne"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        To sign up, visit our website and click on the "Sign Up" or "Start Free Trial" button. Follow the prompts to create your account and begin using the product.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseTwo"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseTwo"
                                        >
                                            02. What steps are involved in setting up my account after signing up?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingTwo"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        After signing up, you will receive a confirmation email with instructions to log in. Once logged in, you can configure settings, invite team members, and start using the product.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseThree"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseThree"
                                        >
                                            03. Do I need to install anything to use your SaaS product?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingThree"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        No installation is required. Our SaaS product is accessed online through a web browser, making it easy to get started without downloading software.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseFour"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseFour"
                                        >
                                            04. Is there a demo or tutorial available to help me get started?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingFour"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        Yes, we provide demos and tutorials to guide you through setting up and using our SaaS product effectively. You can access these resources on our website or request a personalized demo.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingFive">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseFive"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseFive"
                                        >
                                            05. What support options are available if I need help during setup?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingFive"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        We offer email support and live chat assistance to help you with any questions or issues you encounter during the setup process. Our support team is available between 10:00am to 6:00pm.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingSix">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseSix"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseSix"
                                        >
                                            06. Can I integrate your SaaS product with other tools we use?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingSix"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        Yes, our SaaS product supports integration with various third-party tools and platforms. Integration options and instructions are available in our documentation.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingSeven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseSeven"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseSeven"
                                        >
                                            07. Is there a limit to the number of users who can access our account?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseSeven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingSeven"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        yes, you can add max 3 users in free trial period , you can add more users with paid subscription plan through your account settings.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingEight">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseEight"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseEight"
                                        >
                                            08. How can I upgrade my account to access more features?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseEight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingEight"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        You can upgrade your account at any time by logging into your account and selecting the upgrade option in the billing or subscription settings. Changes take effect immediately.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheadingNine">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapseNine"
                                            aria-expanded="false"
                                            aria-controls="faqcollapseNine"
                                        >
                                            09. Do you offer any discounts or promotions for new users?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapseNine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheadingNine"
                                        data-bs-parent="#accordionGeneral"
                                        >
                                        <div className="accordion-body">
                                        Yes, we occasionally run promotions or offer discounts for new users. Check our website or contact our sales team for current offers and promotions.
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-120">
                            <div className="col-lg-4 d-flex justify-content-lg-center align-items-lg-center">
                                <div className="verticle-text">
                                    <h2>Subscriptions</h2>
                                </div>
                                </div>
                                <div className="col-lg-8">
                                <div className="faq-content style-2">
                                    <div className="accordion" id="accordionPayment">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2One">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2One"
                                            aria-expanded="true"
                                            aria-controls="faqcollapse2One"
                                        >
                                            01. What subscription plans do you offer?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2One"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="faqheading2One"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We offer different subscription plans to cater to varying needs and usage levels. Plans may differ in features, storage limits, and the number of users.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Two">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Two"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Two"
                                        >
                                            02. Can I cancel my subscription at any time?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Two"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Two"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, you can cancel your subscription at any time through your account settings. Your subscription will remain active until the end of the current billing period.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Three">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Three"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Three"
                                        >
                                            03. Is there a penalty for canceling my subscription?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Three"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Three"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        No, there are no penalties for canceling your subscription. You will retain access to the service until the end of your billing period.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Four">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Four"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Four"
                                        >
                                            04. How do I downgrade my subscription plan?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Four"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Four"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, you can switch between subscription plans at any time. Changes will take effect immediately, and you will be charged or credited accordingly based on the new plan.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Five">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Five"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Five"
                                        >
                                            05. Do you offer refunds for unused portions of my subscription?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Five"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Five"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We do not offer refunds for unused portions of subscriptions. However, you will have access to the service until the end of your current billing period after cancellation.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Six">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Six"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Six"
                                        >
                                            06. How can I view my billing history and invoices?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Six"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Six"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        You can view your billing history and invoices by logging into your account and accessing the billing or subscription section. Invoices are available for download or printing.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Seven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Seven"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Seven"
                                        >
                                            07. What payment methods do you accept for subscriptions?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Seven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Seven"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We accept major credit cards (Visa, MasterCard, American Express) and PayPal for subscription payments. Bank transfers or cash not accepted
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Eight">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Eight"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Eight"
                                        >
                                            08. Can I pause my subscription temporarily?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Eight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Eight"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We do not currently offer a feature to pause subscriptions. You can cancel your subscription and reactivate it later without losing your account data.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Nine">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Nine"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Nine"
                                        >
                                            09. Can I switch between subscription plans?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Nine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Nine"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, you can switch between subscription plans at any time. Changes will take effect immediately, and you will be charged or credited accordingly based on the new plan.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading2Ten">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse2Ten"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse2Ten"
                                        >
                                            10. How do I know when my subscription will renew?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse2Ten"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading2Ten"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        You will receive a renewal notification via email before your subscription renews. The notification will include the renewal date and details on how to manage your subscription.
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-120">
                            <div className="col-lg-4 d-flex justify-content-lg-center align-items-lg-center">
                                <div className="verticle-text">
                                    <h2>Security</h2>
                                </div>
                                </div>
                                <div className="col-lg-8">
                                <div className="faq-content style-2">
                                    <div className="accordion" id="accordionPayment">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3One">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3One"
                                            aria-expanded="true"
                                            aria-controls="faqcollapse3One"
                                        >
                                            01. How do you ensure the security of my data?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3One"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="faqheading3One"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We prioritize data security and employ industry-standard encryption protocols to protect your information from unauthorized access or breaches.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Two">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Two"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Two"
                                        >
                                            02. Is my data backed up? How often?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Two"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Two"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we perform regular backups of customer data to secure servers. Backup frequency depends on our data retention policies and the nature of the data.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Three">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Three"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Three"
                                        >
                                            03. Do you comply with data protection regulations such as GDPR (for EU residents) ?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Three"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Three"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we are committed to complying with data protection regulations applicable to your jurisdiction, including GDPR.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Four">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Four"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Four"
                                        >
                                            04. Who has access to my data?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Four"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Four"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Access to your data is strictly controlled and limited to authorized personnel who require it for providing support or maintaining the service. We do not share your data with third parties except as required by law or outlined in our privacy policy.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Five">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Five"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Five"
                                        >
                                            05. Do you perform security audits or assessments?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Five"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Five"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we conduct regular security audits and assessments to identify and address potential vulnerabilities in our systems and infrastructure.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Six">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Six"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Six"
                                        >
                                            06. What measures do you have in place to prevent data breaches?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Six"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Six"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We implement various measures, including firewalls, intrusion detection systems, and access controls, to prevent unauthorized access and data breaches.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Seven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Seven"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Seven"
                                        >
                                        07. Can I export my data from your platform?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Seven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Seven"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, you can export your data from our platform. We provide tools or instructions to export your data in a standard format for easy migration or backup.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Eight">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Eight"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Eight"
                                        >
                                            08. How do you handle data deletion requests?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Eight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Eight"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We honor data deletion requests in compliance with applicable data protection laws. You can submit a request through our support channels, and we will process it within a reasonable timeframe.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Nine">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Nine"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Nine"
                                        >
                                            09. Where is my data stored geographically?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Nine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Nine"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Your data may be stored on servers located in different geographic regions based on our infrastructure and service providers. We ensure data remains secure and protected regardless of location.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading3Ten">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse3Ten"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse3Ten"
                                        >
                                            10. Do you provide data encryption in transit and at rest?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse3Ten"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading3Ten"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we use encryption protocols to protect your data during transmission (in transit) and when stored on our servers (at rest), ensuring confidentiality and integrity.
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mb-120">
                            <div className="col-lg-4 d-flex justify-content-lg-center align-items-lg-center">
                                <div className="verticle-text">
                                    <h2>Support</h2>
                                </div>
                                </div>
                                <div className="col-lg-8">
                                <div className="faq-content style-2">
                                    <div className="accordion" id="accordionPayment">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4One">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4One"
                                            aria-expanded="true"
                                            aria-controls="faqcollapse4One"
                                        >
                                            01. How can I contact technical support for assistance?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4One"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="faqheading4One"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        You can contact our technical support team through email, live chat, or phone. Support hours and contact details are available on our website.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Two">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Two"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Two"
                                        >
                                            02. What types of issues does your technical support team assist with?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Two"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Two"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Our technical support team assists with troubleshooting technical issues, providing guidance on using our product features, and addressing account-related inquiries.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Three">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Three"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Three"
                                        >
                                            03. Is technical support available 24/7?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Three"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Three"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Our technical support team operates during 10:00am to 6:00pm to provide timely assistance. For urgent issues outside these hours, you can submit a support ticket for follow-up.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Four">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Four"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Four"
                                        >
                                            04. Do you offer onboarding or training sessions for new users?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Four"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Four"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we provide onboarding sessions and training materials to help new users get acquainted with our SaaS product and maximize its benefits.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Five">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Five"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Five"
                                        >
                                            05. Can technical support help with customizations or integrations?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Five"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Five"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Our technical support team can provide guidance and assistance for standard integrations and configurations. For complex customizations, we may recommend professional services or consultancy.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Six">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Six"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Six"
                                        >
                                            06. Is there a self-service knowledge base or help center available?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Six"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Six"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we maintain a comprehensive knowledge base and help center with articles, FAQs, and troubleshooting guides. You can access these resources to find answers to common questions.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Seven">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Seven"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Seven"
                                        >
                                            07. How quickly can I expect a response from technical support?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Seven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Seven"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        We strive to respond to support inquiries promptly. Response times may vary based on the complexity of the issue and current support volume.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Eight">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Eight"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Eight"
                                        >
                                            08. What information should I provide when contacting technical support?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Eight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Eight"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        When contacting technical support, please provide your account details, a description of the issue, any error messages encountered, and steps you have already taken to troubleshoot.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Nine">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Nine"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Nine"
                                        >
                                            09. Do you offer service level agreements (SLAs) for technical support?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Nine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Nine"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        Yes, we offer SLAs that outline our commitment to response times, issue resolution, and uptime guarantees. SLA details are available to enterprise or premium plan subscribers.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="faqheading4Ten">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#faqcollapse4Ten"
                                            aria-expanded="false"
                                            aria-controls="faqcollapse4Ten"
                                        >
                                            10. How do you handle service outages or disruptions?
                                        </button>
                                        </h2>
                                        <div
                                        id="faqcollapse4Ten"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="faqheading4Ten"
                                        data-bs-parent="#accordionPayment"
                                        >
                                        <div className="accordion-body">
                                        In the event of a service outage or disruption, we promptly notify affected users through our status page, email alerts, or other communication channels. We work diligently to restore service as quickly as possible.
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>  
        </>
    );
}
