import React, { useEffect } from 'react';

import 'react-modal-video/css/modal-video.css';
import 'react-datepicker/dist/react-datepicker.module.css'
import 'yet-another-react-lightbox/styles.css';

import Header from '../components/Header';
import Breadcrumb from '../components/Breadcrumb';
import Footer from '../components/Footer';
import { useLocation } from 'react-router';
import useWow from '../hooks/useWow';

export default function MainLayout({ title, children }) {

    useWow();

    useEffect(() => {
        require("bootstrap/dist/js/bootstrap.bundle.min.js");
    }, []);

    const hideBreadcrumbRoutes = [
        "/",
        "/not-found"
    ];

    const currentSlug = useLocation().pathname;
    const shouldRenderBreadcrumb = !hideBreadcrumbRoutes.some(
        (route) => route === currentSlug
    );

    return(
        <>
            <Header title={title}/>
            {shouldRenderBreadcrumb && <Breadcrumb />}
            {children}
            <Footer/>
        </>
    );
}
