import React from 'react';
import MainLayout from '../../components/MainLayout';
import { Link } from 'react-router-dom';

export default function LawEnforcementData() {

    return(
        <>
            <MainLayout title={'WorkXCRM Terms of Service - law enforcement & data disclosure requests policy'} >
                <section id="law-enforcement-data-section">
                    <div className="container margin-top-container">
                            <p>
                            <strong>Awapal Solutions Private Limited dba WorkX (“WorkX”/”we”/”us”) is a Indian company based in Noida,</strong> Uttar Pradesh, India and we provide access to our proprietary business management platform to home service business operators and field service providers. These guidelines are intended for those seeking information about a WorkX account or looking to take action in respect of WorkX clients or resources hosted on our platform.  Please note that claims of copyright infringement are addressed separately here:  <Link legacyBehavior to="/legal/copyright-complaints"><a>https://Workxcrm.com/copyright-complaints</a></Link></p>
                            <p><strong> Disclosure Requests </strong></p>
                            <ul className="wp-block-list" >
                                <p>Safeguarding our users’ data is vital to the trust our users place in our service to keep their data, including the data of their customers, secure. For the most part, as a Indian company WorkX’s ability to disclose user information is governed by the Personal Information Protection and Electronic Documents Act, S.C. 2000, c. 5 (“PIPEDA”) and other applicable privacy legislation. Accordingly, WorkX may disclose certain user information in response to specific types of legal process, including subpoenas, court orders, and search warrants. Any request from a non-account owner for user information must be made through a valid subpoena, court order, or search warrant (see further below)</p>
                                <p>To request information regarding jobs, invoices, or payments facilitated through WorkX, the applicable WorkX customer’s name and a date range must be specifically included in the request. WorkX cannot process overly broad or vague requests and we cannot guarantee that we will have any given sets of information for any particular user. This in part depends on our data retention policies which vary according to the type of information and actions of users.</p>
                                <p>WorkX shall use commercially reasonable efforts to provide all information validly requested in accordance with this policy. WorkX reserves the right to charge its users, on a time and materials basis, for WorkX’s efforts taken to respond to valid law enforcement requests initiated at the User’s request.</p>                    
                            </ul>
                            <p><strong>Requests from Government Agencies/Law Enforcement</strong></p>
                            <ul className="wp-block-list" style={{listStyle:"disc"}} >
                                <li>Except in rare exigent circumstances, WorkX turns over user information only upon receipt of a valid, Canadian court order, subpoena, or search warrant issued by a Canadian authority. Additionally, we may notify affected users about any requests for their account information, unless prohibited from doing so by law or court order (see more below).   </li>
                                <li> For <strong>legal requests from government agencies/law enforcement outside of Canada</strong>, we require that the request be served via a Canadian court (e.g., under the procedures of an applicable mutual legal assistance treaty (MLAT)) </li>
                            </ul>
                            <p><strong>Notification to WorkX Users</strong></p>
                            <ul className="wp-block-list" >
                                <p>If a request for information is valid, we will preserve the necessary information, and then make a reasonable effort to notify any affected account owner(s) by sending a message to their verified email address. We will also notify users of any legal process regarding their account unless we are prohibited by law or court order from doing so. Notwithstanding the foregoing, we may choose not to notify a user if we deem, in our sole discretion, that providing notice would be ineffective, prejudicial, might create a risk of injury or bodily harm to an individual or group, or to our property, or is otherwise inappropriate in the circumstances. In most cases, upon notification to the user, that user may have the right to file an objection with the court or otherwise legally challenge the request. If, prior to the deadline, we receive notice from the user that he or she has filed an objection to challenge a request, no information will be delivered until that process concludes</p>
                            </ul>
                            <p><strong>Serving Process on WorkX</strong></p>
                            <ul className="wp-block-list" >
                                <li>Any request for user information must include a valid email address for us to return the information or contact with questions. WorkX communicates only via email with a confirmed receipt.</li>
                                <li>Where permitted, WorkX prefers to receive service via email to <a href="mailto:legal@workxcrm.com" >legal@workxcrm.com</a> Legal process can also be served by mail to:</li>
                                <li>B-4 First Floor, Sector 63, Noida, U.P 201301, India</li>
                                <li>Please make your requests as specific and narrow as possible, including the following information:</li>
                                <ul className="wp-block-list" style={{listStyle:"disc"}}  >
                                    <li> Full information about authority issuing the request for information </li>
                                    <li>The name and badge/ID of the responsible agent</li>
                                    <li>An official email address and contact phone number</li>
                                    <li>The IP address, date range, domain name(s) of interest</li>
                                    <li>The description of the types of records you need</li>
                                </ul>
                            </ul>
                            <p>Please allow at least two weeks for us to be able to look into your request. WorkX reserves the right to make changes to any of the foregoing practices in its sole discretion.</p>
                    </div>
                </section>
    </MainLayout>
        </>
    );
}
