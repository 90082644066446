import React, { useEffect, useMemo } from 'react';
import MainLayout from '../components/MainLayout';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Autoplay,
    EffectFade,
    Navigation,
    Pagination,
  } from "swiper/modules";
import SwiperCore from 'swiper'
SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

export default function OurStory() {

    const settings = useMemo(() => {
        return {
          slidesPerView: "auto",
          speed: 1500,
          spaceBetween: 30,
          grabCursor: true,
          loop: true,
          autoplay: {
            delay: 2500, // Autoplay duration in milliseconds
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".home2-process-next",
            prevEl: ".home2-process-prev",
          },
    
          breakpoints: {
            280: {
              slidesPerView: 1,
            },
            386: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1400: {
              slidesPerView: 4,
            },
          },
        };
    }, []);

    useEffect(() => {
        const element = document.querySelectorAll(".badge__char");
        const step = 360 / element.length;
        element.forEach((elem, i) => {
            elem.style.setProperty("--char-rotate", i * step + "deg");
        });
    }, []);

    return(
        <>
            <MainLayout title={'WorkxCRM\'s Journey: Our Story of Success and Innovation'}>
                {/* Start Home4 About section */}
                <div className="home4-about-section mb-130 mt-120" id="our-story-section">
                    <div className="container">
                    <div className="row align-items-center mb-110">
                        <div
                        className="col-xl-9 wow animate fadeInLeft"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="about-content-wrap">
                            <div className="about-section-title mb-40">
                            <span>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                >
                                <g>
                                    <circle cx={5} cy={5} r={5} />
                                </g>
                                </svg>
                                Unveiling Work WorkX
                            </span>
                            <h2>"Catalyzing Success: Our Evolution in Business Empowerment"
                            </h2>
                            <p>At WorkX, our journey began with a vision to innovate how businesses manage operations and customer relationships. From our humble start, driven by a passion for technology, we've grown into a dynamic force in the industry. Today, we're proud of our achievements, fueled by a commitment to developing cutting-edge solutions that meet evolving client needs and foster lasting partnerships.
                            </p>
                            </div>
                            {/* <Link to="/about" className="explore-btn">
                            Learn More
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={11}
                                height={11}
                                viewBox="0 0 10 10"
                            >
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.48878 0.885308L0 9.37364L0.626356 10L9.11469 1.51122V7.38037H10V0H2.61963V0.885308H8.48878Z"
                                ></path>
                            </svg>
                            </Link> */}
                        </div>
                        </div>
                        <div
                        className="col-xl-3 wow animate fadeInRight"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="text-rotate-area">
                            <div className="badge">
                            <span className="badge__char">W</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char">L</span>
                            <span className="badge__char">C</span>
                            <span className="badge__char">O</span>
                            <span className="badge__char">M</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char" />
                            <span className="badge__char">T</span>
                            <span className="badge__char">O</span>
                            <span className="badge__char" />
                            <span className="badge__char">W</span>
                            <span className="badge__char">O</span>
                            <span className="badge__char">R</span>
                            <span className="badge__char">K</span>
                            <span className="badge__char">X</span>
                            <span className="badge__char">*</span>
                            <span className="badge__char">M</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char">T</span>
                            <span className="badge__char">S</span>
                            <span className="badge__char" />
                            <span className="badge__char">E</span>
                            <span className="badge__char">X</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char">C</span>
                            <span className="badge__char">U</span>
                            <span className="badge__char">T</span>
                            <span className="badge__char">I</span>
                            <span className="badge__char">O</span>
                            <span className="badge__char">N</span>
                            <span className="badge__char">*</span>
                            <span className="badge__char">O</span>
                            <span className="badge__char">F</span>
                            <span className="badge__char" />
                            <span className="badge__char">C</span>
                            <span className="badge__char">R</span>
                            <span className="badge__char">E</span>
                            <span className="badge__char">A</span>
                            <span className="badge__char">T</span>
                            <span className="badge__char">I</span>
                            <span className="badge__char">V</span>
                            <span className="badge__char">I</span>
                            <span className="badge__char">T</span>
                            <span className="badge__char">Y</span>
                            <span className="badge__char">*</span>
                            <div className="inner-circle"></div>
                            </div>
                            <div className="half-circle-img-area">
                            <img
                                src="/assets/img/innerpage/about-half-circle-img.jpg"
                                alt=""
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Home4 About section */}

                {/* Start Home5 Why choose Us section */}
                <div className="home5-why-choose-section mb-120">
                    <div className="container">
                    <div className="row mb-60">
                        <div
                        className="col-lg-12 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="section-title5 two">
                            <span className="sub-title5 two">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                viewBox="0 0 14 14"
                            >
                                <path d="M3.7081 12.9544C3.41861 13.1128 3.09011 12.8352 3.14861 12.4808L3.7711 8.69694L1.12886 6.01223C0.882112 5.76104 1.01036 5.30186 1.34111 5.25226L5.0146 4.69548L6.6526 1.23399C6.80035 0.922003 7.2001 0.922003 7.34785 1.23399L8.98584 4.69548L12.6593 5.25226C12.9901 5.30186 13.1183 5.76104 12.8708 6.01223L10.2293 8.69694L10.8518 12.4808C10.9103 12.8352 10.5818 13.1128 10.2923 12.9544L6.9991 11.1497L3.7081 12.9544Z" />
                            </svg>
                            Discover the Advantage
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                viewBox="0 0 14 14"
                            >
                                <path d="M3.7081 12.9544C3.41861 13.1128 3.09011 12.8352 3.14861 12.4808L3.7711 8.69694L1.12886 6.01223C0.882112 5.76104 1.01036 5.30186 1.34111 5.25226L5.0146 4.69548L6.6526 1.23399C6.80035 0.922003 7.2001 0.922003 7.34785 1.23399L8.98584 4.69548L12.6593 5.25226C12.9901 5.30186 13.1183 5.76104 12.8708 6.01223L10.2293 8.69694L10.8518 12.4808C10.9103 12.8352 10.5818 13.1128 10.2923 12.9544L6.9991 11.1497L3.7081 12.9544Z" />
                            </svg>
                            </span>
                            <h2>WorkX combines extensive industry experience with advanced technology to <span>automate service business processes.</span></h2>
                            <p>Our evolution is marked by milestones of innovation, from launching flagship products to expanding our global footprint. Each success story from our clients reaffirms our dedication to excellence and continuous improvement.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="row g-lg-4 gy-5">
                        <div
                        className="col-lg-5 col-md-8 wow animate fadeInLeft"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="why-choose-img">
                            <img src="/assets/img/innerpage/why-choose.jpg" alt="" />
                        </div>
                        </div>
                        <div className="col-lg-7">
                        <div className="why-choose-content">
                        <p>Looking ahead, we remain committed to empowering businesses of all sizes with tools that drive efficiency, growth, and unparalleled customer satisfaction.</p>
                            <div className="about-coundown-area">
                            <div className="row g-0">
                                <div
                                className="col-md-6 wow animate fadeInUp"
                                data-wow-delay="200ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-countdown">
                                    <div className="number">
                                    <h2 className="counter">
                                        <CountUp end={350} delay={1} />
                                    </h2>
                                    <span>+</span>
                                    </div>
                                    <div className="content">
                                    <p>Customers</p>
                                    </div>
                                </div>
                                </div>
                                <div
                                className="col-md-6 d-flex justify-content-end wow animate fadeInUp"
                                data-wow-delay="400ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-countdown two">
                                    <div className="number">
                                    <h2 className="counter">
                                        <CountUp end={800} delay={1} />
                                    </h2>
                                    <span>+</span>
                                    </div>
                                    <div className="content">
                                    <p>Users</p>
                                    </div>
                                </div>
                                </div>
                                <div
                                className="col-md-6 d-flex justify-content-center wow animate fadeInUp"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-countdown three">
                                    <div className="number">
                                    <h2 className="counter">
                                        <CountUp end={5} delay={1} />
                                    </h2>
                                    <span>K</span>
                                    <span>+</span>
                                    </div>
                                    <div className="content">
                                    <p>Sites billed</p>
                                    </div>
                                </div>
                                </div>
                                <div
                                className="col-md-6 d-flex justify-content-end wow animate fadeInUp"
                                data-wow-delay="800ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-countdown four">
                                    <div className="number">
                                    <h2 className="counter">
                                        <CountUp end={6} delay={1} />
                                    </h2>
                                    </div>
                                    <div className="content">
                                    <p>Countries servicng in</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Home5 Why choose Us section */}

                {/* Start Home2 Process section */}
                <div className="home2-process-section two mb-120">
                    <div className="container">
                    <div
                        className="section-title5 text-center mb-70 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                    >
                        <span className="sub-title5 two">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 14 14"
                        >
                            <path d="M3.7081 12.9544C3.41861 13.1128 3.09011 12.8352 3.14861 12.4808L3.7711 8.69694L1.12886 6.01223C0.882112 5.76104 1.01036 5.30186 1.34111 5.25226L5.0146 4.69548L6.6526 1.23399C6.80035 0.922003 7.2001 0.922003 7.34785 1.23399L8.98584 4.69548L12.6593 5.25226C12.9901 5.30186 13.1183 5.76104 12.8708 6.01223L10.2293 8.69694L10.8518 12.4808C10.9103 12.8352 10.5818 13.1128 10.2923 12.9544L6.9991 11.1497L3.7081 12.9544Z" />
                        </svg>
                        Working Process
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={14}
                            height={14}
                            viewBox="0 0 14 14"
                        >
                            <path d="M3.7081 12.9544C3.41861 13.1128 3.09011 12.8352 3.14861 12.4808L3.7711 8.69694L1.12886 6.01223C0.882112 5.76104 1.01036 5.30186 1.34111 5.25226L5.0146 4.69548L6.6526 1.23399C6.80035 0.922003 7.2001 0.922003 7.34785 1.23399L8.98584 4.69548L12.6593 5.25226C12.9901 5.30186 13.1183 5.76104 12.8708 6.01223L10.2293 8.69694L10.8518 12.4808C10.9103 12.8352 10.5818 13.1128 10.2923 12.9544L6.9991 11.1497L3.7081 12.9544Z" />
                        </svg>
                        </span>
                        <h2>Bringing the best IT <span> Vendors To You.</span></h2>
                    </div>
                    </div>
                    <div className="container-fluid p-0">
                    <div className="process-slider-area">
                        <div className="row">
                        <div className="col-lg-12">
                            <Swiper {...settings} className="swiper home2-process-slider">
                            <div className="swiper-wrapper">
                                <SwiperSlide
                                className="swiper-slide wow animate fadeInDown"
                                data-wow-delay="200ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-process">
                                    <div className="step">
                                    <div className="number">
                                        <h6>Step</h6>
                                        <span>01</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <h4>Client Consultation</h4>
                                    <p>
                                    Sed accumsan sem cursus luctus porta. amem Phasellu du enim, efficitur quis velit ac, fringilla posuere leo fusci.
                                    </p>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide
                                className="swiper-slide wow animate fadeInDown"
                                data-wow-delay="400ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-process">
                                    <div className="step">
                                    <div className="number">
                                        <h6>Step</h6>
                                        <span>02</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <h4>Strategy Development</h4>
                                    <p>Sed accumsan sem cursus luctus porta. amem Phasellu du enim, efficitur quis velit ac, fringilla posuere leo fusci.</p>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide
                                className="swiper-slide wow animate fadeInDown"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-process">
                                    <div className="step">
                                    <div className="number">
                                        <h6>Step</h6>
                                        <span>03</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <h4>Market Research</h4>
                                    <p>Sed accumsan sem cursus luctus porta. amem Phasellu du enim, efficitur quis velit ac, fringilla posuere leo fusci.</p>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide
                                className="swiper-slide wow animate fadeInDown"
                                data-wow-delay="800ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-process">
                                    <div className="step">
                                    <div className="number">
                                        <h6>Step</h6>
                                        <span>04</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <h4>Campaign Planning</h4>
                                    <p>Sed accumsan sem cursus luctus porta. amem Phasellu du enim, efficitur quis velit ac, fringilla posuere leo fusci.</p>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide
                                className="swiper-slide"
                                data-wow-delay="800ms"
                                data-wow-duration="1500ms"
                                >
                                <div className="single-process">
                                    <div className="step">
                                    <div className="number">
                                        <h6>Step</h6>
                                        <span>05</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <h4>Campaign Planning</h4>
                                    <p>Sed accumsan sem cursus luctus porta. amem Phasellu du enim, efficitur quis velit ac, fringilla posuere leo fusci.</p>
                                    </div>
                                </div>
                                </SwiperSlide>
                            </div>
                            </Swiper>
                            <div className="slider-btn-area">
                            <div className="slider-btn home2-process-prev">
                                <i className="bi bi-arrow-left" />
                            </div>
                            <div className="content">
                                <p>Overcome the IT Challenges</p>
                            </div>
                            <div className="slider-btn home2-process-next">
                                <i className="bi bi-arrow-right" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Home2 Process section */}

                {/* Start Partner Section */}
                <div className="partnership-area mb-120">
                    <div className="container">
                    <div className="row">
                        <div
                        className="col-lg-12 mb-70 d-flex flex-wrap gap-3 align-items-end justify-content-between wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="section-title5">
                        <h2><span>Our</span>Partners</h2>
                        </div>
                        <div className="total-partner">
                            <a href="/integrations">
                            Almost 10+ Partner we have
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                            >
                                <path d="M8.33624 2.84003L1.17627 10L0 8.82373L7.15914 1.66376H0.849347V0H10V9.15065H8.33624V2.84003Z" />
                            </svg>
                            </a>
                        </div>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div
                        className="col-lg-3 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="single-pertner">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={88}
                            height={105}
                            viewBox="0 0 88 105"
                            fill="none"
                            >
                            <path d="M66.8167 -17.6053C69.7962 -13.2647 76.2037 -13.2647 79.1833 -17.6053L87.7974 -30.154C91.4643 -35.4958 99.8342 -32.7711 99.6538 -26.2944L99.2297 -11.068C99.083 -5.80053 104.271 -2.02811 109.237 -3.79168L123.597 -8.89173C129.707 -11.0618 134.88 -3.92864 130.918 1.20465L121.659 13.2019C118.436 17.3786 120.418 23.4895 125.479 24.979L140.041 29.2644C146.261 31.0949 146.261 39.9051 140.041 41.7356L125.479 46.021C120.418 47.5105 118.436 53.6215 121.659 57.7981L130.918 69.7954C134.88 74.9286 129.707 82.0618 123.597 79.8917L109.237 74.7917C104.271 73.0281 99.083 76.8005 99.2297 82.068L99.6538 97.2944C99.8342 103.771 91.4643 106.496 87.7974 101.154L79.1833 88.6053C76.2038 84.2647 69.7963 84.2647 66.8167 88.6053L58.2026 101.154C54.5357 106.496 46.1658 103.771 46.3462 97.2944L46.7701 82.0734C46.9169 76.8043 41.7258 73.0317 36.7595 74.7983L22.4433 79.8907C16.3362 82.063 11.1603 74.9374 15.1145 69.8013L24.3582 57.7948C27.5743 53.6174 25.5904 47.513 20.5328 46.0246L5.95906 41.7356C-0.260798 39.9051 -0.260805 31.0949 5.95905 29.2644L20.5328 24.9754C25.5904 23.487 27.5743 17.3826 24.3582 13.2052L15.1145 1.19866C11.1603 -3.93743 16.3362 -11.063 22.4433 -8.89069L36.7595 -3.79829C41.7258 -2.03175 46.9169 -5.80433 46.7701 -11.0734L46.3462 -26.2944C46.1658 -32.7711 54.5357 -35.4958 58.2026 -30.154L66.8167 -17.6053Z" />
                            </svg>
                            <span>Technology</span>
                            <div className="partner-logos">
                                <img className="white" src="/assets/img/icon/logo-11.png" alt=""/>
                                <img className="black" src="/assets/img/icon/logo-01.png" alt=""/>
                            </div>
                            <p>Google Local Services</p>
                        </div>
                        </div>
                        <div
                        className="col-lg-3 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="400ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="single-pertner">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={88}
                            height={105}
                            viewBox="0 0 88 105"
                            fill="none"
                            >
                            <path d="M66.8167 -17.6053C69.7962 -13.2647 76.2037 -13.2647 79.1833 -17.6053L87.7974 -30.154C91.4643 -35.4958 99.8342 -32.7711 99.6538 -26.2944L99.2297 -11.068C99.083 -5.80053 104.271 -2.02811 109.237 -3.79168L123.597 -8.89173C129.707 -11.0618 134.88 -3.92864 130.918 1.20465L121.659 13.2019C118.436 17.3786 120.418 23.4895 125.479 24.979L140.041 29.2644C146.261 31.0949 146.261 39.9051 140.041 41.7356L125.479 46.021C120.418 47.5105 118.436 53.6215 121.659 57.7981L130.918 69.7954C134.88 74.9286 129.707 82.0618 123.597 79.8917L109.237 74.7917C104.271 73.0281 99.083 76.8005 99.2297 82.068L99.6538 97.2944C99.8342 103.771 91.4643 106.496 87.7974 101.154L79.1833 88.6053C76.2038 84.2647 69.7963 84.2647 66.8167 88.6053L58.2026 101.154C54.5357 106.496 46.1658 103.771 46.3462 97.2944L46.7701 82.0734C46.9169 76.8043 41.7258 73.0317 36.7595 74.7983L22.4433 79.8907C16.3362 82.063 11.1603 74.9374 15.1145 69.8013L24.3582 57.7948C27.5743 53.6174 25.5904 47.513 20.5328 46.0246L5.95906 41.7356C-0.260798 39.9051 -0.260805 31.0949 5.95905 29.2644L20.5328 24.9754C25.5904 23.487 27.5743 17.3826 24.3582 13.2052L15.1145 1.19866C11.1603 -3.93743 16.3362 -11.063 22.4433 -8.89069L36.7595 -3.79829C41.7258 -2.03175 46.9169 -5.80433 46.7701 -11.0734L46.3462 -26.2944C46.1658 -32.7711 54.5357 -35.4958 58.2026 -30.154L66.8167 -17.6053Z" />
                            </svg>
                            <span>Storage</span>
                            <div className="partner-logos">
                                <img className="white" src="/assets/img/icon/logo-22.png" alt=""/>
                                <img className="black" src="/assets/img/icon/logo-02.png" alt=""/>
                            </div>
                            <p>Google Drive</p>
                        </div>
                        </div>
                        <div
                        className="col-lg-3 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="600ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="single-pertner">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={88}
                            height={105}
                            viewBox="0 0 88 105"
                            fill="none"
                            >
                            <path d="M66.8167 -17.6053C69.7962 -13.2647 76.2037 -13.2647 79.1833 -17.6053L87.7974 -30.154C91.4643 -35.4958 99.8342 -32.7711 99.6538 -26.2944L99.2297 -11.068C99.083 -5.80053 104.271 -2.02811 109.237 -3.79168L123.597 -8.89173C129.707 -11.0618 134.88 -3.92864 130.918 1.20465L121.659 13.2019C118.436 17.3786 120.418 23.4895 125.479 24.979L140.041 29.2644C146.261 31.0949 146.261 39.9051 140.041 41.7356L125.479 46.021C120.418 47.5105 118.436 53.6215 121.659 57.7981L130.918 69.7954C134.88 74.9286 129.707 82.0618 123.597 79.8917L109.237 74.7917C104.271 73.0281 99.083 76.8005 99.2297 82.068L99.6538 97.2944C99.8342 103.771 91.4643 106.496 87.7974 101.154L79.1833 88.6053C76.2038 84.2647 69.7963 84.2647 66.8167 88.6053L58.2026 101.154C54.5357 106.496 46.1658 103.771 46.3462 97.2944L46.7701 82.0734C46.9169 76.8043 41.7258 73.0317 36.7595 74.7983L22.4433 79.8907C16.3362 82.063 11.1603 74.9374 15.1145 69.8013L24.3582 57.7948C27.5743 53.6174 25.5904 47.513 20.5328 46.0246L5.95906 41.7356C-0.260798 39.9051 -0.260805 31.0949 5.95905 29.2644L20.5328 24.9754C25.5904 23.487 27.5743 17.3826 24.3582 13.2052L15.1145 1.19866C11.1603 -3.93743 16.3362 -11.063 22.4433 -8.89069L36.7595 -3.79829C41.7258 -2.03175 46.9169 -5.80433 46.7701 -11.0734L46.3462 -26.2944C46.1658 -32.7711 54.5357 -35.4958 58.2026 -30.154L66.8167 -17.6053Z" />
                            </svg>
                            <span>Accounting</span>
                            <div className="partner-logos">
                                <img className="white" src="/assets/img/icon/logo-33.png" alt="" />
                                <img className="black" src="/assets/img/icon/logo-03.png" alt="" />
                            </div>
                            <p>Quickbooks</p>
                        </div>
                        </div>
                        <div
                        className="col-lg-3 col-sm-6 wow animate fadeInDown"
                        data-wow-delay="800ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="single-pertner">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={88}
                            height={105}
                            viewBox="0 0 88 105"
                            fill="none"
                            >
                            <path d="M66.8167 -17.6053C69.7962 -13.2647 76.2037 -13.2647 79.1833 -17.6053L87.7974 -30.154C91.4643 -35.4958 99.8342 -32.7711 99.6538 -26.2944L99.2297 -11.068C99.083 -5.80053 104.271 -2.02811 109.237 -3.79168L123.597 -8.89173C129.707 -11.0618 134.88 -3.92864 130.918 1.20465L121.659 13.2019C118.436 17.3786 120.418 23.4895 125.479 24.979L140.041 29.2644C146.261 31.0949 146.261 39.9051 140.041 41.7356L125.479 46.021C120.418 47.5105 118.436 53.6215 121.659 57.7981L130.918 69.7954C134.88 74.9286 129.707 82.0618 123.597 79.8917L109.237 74.7917C104.271 73.0281 99.083 76.8005 99.2297 82.068L99.6538 97.2944C99.8342 103.771 91.4643 106.496 87.7974 101.154L79.1833 88.6053C76.2038 84.2647 69.7963 84.2647 66.8167 88.6053L58.2026 101.154C54.5357 106.496 46.1658 103.771 46.3462 97.2944L46.7701 82.0734C46.9169 76.8043 41.7258 73.0317 36.7595 74.7983L22.4433 79.8907C16.3362 82.063 11.1603 74.9374 15.1145 69.8013L24.3582 57.7948C27.5743 53.6174 25.5904 47.513 20.5328 46.0246L5.95906 41.7356C-0.260798 39.9051 -0.260805 31.0949 5.95905 29.2644L20.5328 24.9754C25.5904 23.487 27.5743 17.3826 24.3582 13.2052L15.1145 1.19866C11.1603 -3.93743 16.3362 -11.063 22.4433 -8.89069L36.7595 -3.79829C41.7258 -2.03175 46.9169 -5.80433 46.7701 -11.0734L46.3462 -26.2944C46.1658 -32.7711 54.5357 -35.4958 58.2026 -30.154L66.8167 -17.6053Z" />
                            </svg>
                            <span>Emailing</span>
                            <div className="partner-logos">
                                <img className="white" src="/assets/img/icon/logo-44.png" alt="" />
                                <img className="black" src="/assets/img/icon/logo-04.png" alt="" />
                            </div>
                            <p>Brevo</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Partner Section */}

                {/* Start Home4 Award section */}
                <div className="home4-award-section mb-130">
                    <div className="container-fluid">
                    <div className="row g-lg-4 gy-5">
                        <div
                        className="col-lg-5 wow animate fadeInLeft"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                        >
                        <div className="section-title-wrap">
                            <div className="section-title3 mb-40">
                            <span>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={10}
                                height={10}
                                viewBox="0 0 10 10"
                                >
                                <g>
                                    <circle cx={5} cy={5} r={5} />
                                </g>
                                </svg>
                                Our Award Time
                            </span>
                            <h2>
                                Celebrating WorkX's <span>Award-Winning </span>Excellence.
                            </h2>
                            </div>
                            <Link to="#" className="details-button">
                            About Us More
                            <svg viewBox="0 0 13 20">
                                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
                            </svg>
                            </Link>
                            <div className="vector">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                            >
                                <g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.4993 24V0H12.4993V24H11.4993Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M24 12.499H0V11.499H24V12.499Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.4993 24V0H12.4993V24H11.4993Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M24 12.499H0V11.499H24V12.499Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.23291 23.0226L15.8334 0.617188L16.767 0.975552L8.16649 23.3809L7.23291 23.0226Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M23.0228 16.7675L0.617432 8.16698L0.975796 7.2334L23.3812 15.8339L23.0228 16.7675Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.44971 20.4344L19.8177 2.88184L20.549 3.56383L4.18106 21.1164L3.44971 20.4344Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20.4346 20.5485L2.88208 4.18057L3.56408 3.44922L21.1166 19.8172L20.4346 20.5485Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.607178 15.8229L23.023 7.23145L23.381 8.16545L0.96516 16.7569L0.607178 15.8229Z"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.8241 23.391L7.23267 0.975169L8.16667 0.617188L16.7581 23.033L15.8241 23.391Z"
                                />
                                </g>
                            </svg>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-7">
                        <div className="sevices-wrap2">
                            <div
                            className="single-services wow animate fadeInDown"
                            data-wow-delay="200ms"
                            data-wow-duration="1500ms"
                            >
                            <div className="content">
                                <span>2022 Jan</span>
                                <h5>Started Product Development</h5>
                                <p>Started our journey</p>
                            </div>
                            {/* <Link className="explore-btn" href="/about">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.582 1.41649L0 14.9978L1.00217 16L14.5835 2.41795L14.5835 11.8086H16L16 0L4.1914 0V1.41649L13.582 1.41649Z"
                                />
                                </svg>
                            </Link> */}
                            {/* <div className="services-img">
                                <img src="/assets/img/innerpage/award-img1.jpg" alt="" />
                            </div> */}
                            </div>
                            <div
                            className="single-services wow animate fadeInDown"
                            data-wow-delay="400ms"
                            data-wow-duration="1500ms"
                            >
                            <div className="content">
                                <span>2023 Jan</span>
                                <h5>Launched Beta Version</h5>
                                <p>The day our vision come true</p>
                            </div>
                            {/* <Link className="explore-btn" href="/about">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.582 1.41649L0 14.9978L1.00217 16L14.5835 2.41795L14.5835 11.8086H16L16 0L4.1914 0V1.41649L13.582 1.41649Z"
                                />
                                </svg>
                            </Link> */}
                            {/* <div className="services-img">
                                <img src="/assets/img/innerpage/award-img2.jpg" alt="" />
                            </div> */}
                            </div>
                            <div
                            className="single-services wow animate fadeInDown"
                            data-wow-delay="600ms"
                            data-wow-duration="1500ms"
                            >
                            <div className="content">
                                <span>2023 Oct</span>
                                <h5>New Features Released</h5>
                                <p></p>
                            </div>
                            {/* <Link className="explore-btn" href="/about">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.582 1.41649L0 14.9978L1.00217 16L14.5835 2.41795L14.5835 11.8086H16L16 0L4.1914 0V1.41649L13.582 1.41649Z"
                                />
                                </svg>
                            </Link> */}
                            {/* <div className="services-img">
                                <img src="/assets/img/innerpage/award-img3.jpg" alt="" />
                            </div> */}
                            </div>
                            <div
                            className="single-services wow animate fadeInDown"
                            data-wow-delay="800ms"
                            data-wow-duration="1500ms"
                            >
                            <div className="content">
                                <span>2024 Feb</span>
                                <h5>Initiated Mobile App Development</h5>
                                <p>Site Of The Day</p>
                            </div>
                            {/* <Link className="explore-btn" href="/about">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.582 1.41649L0 14.9978L1.00217 16L14.5835 2.41795L14.5835 11.8086H16L16 0L4.1914 0V1.41649L13.582 1.41649Z"
                                />
                                </svg>
                            </Link> */}
                            {/* <div className="services-img">
                                <img src="/assets/img/innerpage/award-img4.jpg" alt="" />
                            </div> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Home4 Award section */}
            </MainLayout>
        </>
    );
}
