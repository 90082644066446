import React from 'react';
import MainLayout from '../components/MainLayout';
import PricingComponent from '../components/PricingComponent';

export default function Pricing() {

    return(
        <>
            <MainLayout title={'WorkX CRM Pricing Plans | Affordable & Flexible Solutions for Your Business'}>
                <PricingComponent />
            </MainLayout>
        </>
    );
}
