import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import React from 'react';

import Dashboard from './dashboard';
import Career from './career';
import Contact from './contact';
import Faqs from './faqs';
import Features from './features';
import HelpCenter from './help-center';
import Industries from './industries';
import Integrations from './integrations';
import OurStory from './our-story';
import PartnerProgram from './partner-program';
import Pricing from './pricing';
import Team from './team';
import WhyWorkx from './why-workx';
import Registration from './registration';
import LegalPages from './LegalPages';

function App() {
    return (
        <>
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<Dashboard/>} />
                    <Route path='/career' element={<Career/>} />
                    <Route path='/contact' element={<Contact/>} />
                    <Route path='/faqs' element={<Faqs/>} />
                    <Route path='/features' element={<Features/>} />
                    <Route path='/help-center' element={<HelpCenter/>} />
                    <Route path='/industries' element={<Industries/>} />
                    <Route path='/integrations' element={<Integrations/>} />
                    <Route path='/our-story' element={<OurStory/>} />
                    <Route path='/partner-program' element={<PartnerProgram/>} />
                    <Route path='/pricing' element={<Pricing/>} />
                    <Route path='/registration' element={<Registration/>} />
                    <Route path='/team' element={<Team/>} />
                    <Route path='/why-workx' element={<WhyWorkx/>} />
                    <Route path="/legal/*" element={<LegalPages/>} />
                </Routes>
            </Router>
            </React.StrictMode>
        </>
    );
}

export default App;