import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function AccountCreationfraud() {

    return(
        <>
            <MainLayout title={'Account security and fraud prevention'}>
                <section id="account-creation-fraud-section">
                    <div className="container margin-top-container">
                        <p>This agreement (set out the terms on which WorkX makes available certain account security and fraud prevention tools through the WorkX platform (the “<strong>Security Service Terms</strong>”).   These terms are supplemental to the WorkX Terms of Service and Privacy Policy which bind all users of the Website, App and Service. Capitalised terms not defined in these Security Service Terms have the meanings given in the WorkX Terms of Service. </p>
                        <p><strong>1. Overview </strong></p>
                        <ul className="wp-block-list" >
                            <li>The Security Service is comprised of a suite of SaaS based digital trust and Security products which assist Users with account security and the prediction and prevention of fraudulent activity  through the use of the machine learning platform (the “<strong>Security Services</strong>”). The Security Services are currently provided by Sift Science, Inc. (the “Provider”).   You acknowledge and agree that WorkX cannot guarantee account Security or fraud prevention or detection but the Security Services operated by the Provider may assist in identification of potentially fraudulent transactions and unauthorised activity in a User’s account.   By accepting this Agreement, you are also accepting and agreeing to be bound by the Provider Privacy Policy in respect of their processing and handling of your data and, which constitutes a legal agreement between you and the Provider.  Please note that the Security Services are made available to Users at the sole discretion of WorkX at all times. WorkX reserves the right to collect certain additional information from Users in order to provide this service.</li>
                        </ul>
                        <p><strong>2. User Data</strong></p>
                            <ul className="wp-block-list">
                                <li>1. You hereby grant to Provider a limited, royalty-free, non-exclusive, worldwide right and license to access, collect, use, process, and store all materials, content and data sent, submitted or uploaded to the platform or otherwise provided by you (”<strong>Customer Data</strong>”)  only to use the Security Services as set forth in this Agreement. You grant to Provider a limited, royalty-free, non-exclusive, worldwide right and license to access, collect, use, process, store, copy, and create derivative works from the Customer Data only to provide, maintain, and improve the Security Services.</li>
                                <li>2. <strong>Use of Customer Data.</strong>  You acknowledge and agrees that Provider may use the Customer Data in accordance with the Provider’s Privacy Notice for as long as reasonably necessary for the limited purpose of providing, maintaining, and improving the Security Services (the “<strong>Permitted Purpose</strong>”).  You acknowledge and agree that, in connection with the Permitted Purpose, Customer Data will be commingled with data received from other customers of the Security Services. The Customer Data shall not itself be made available to any other customer.</li>                    
                                <li>3. <strong>Feedback.</strong>  If You provide WorkX or the Provider with any feedback regarding the Security Services, You grant to Provider an unlimited, irrevocable, perpetual, sublicensable, royalty-free licence to use any such feedback or suggestions for any purpose without any obligation or compensation to You.</li>
                                <li>4. <strong>Aggregated Data.</strong>  You acknowledge and agree that Provider may use data derived from Customer Data that is aggregated with data received from other customers </li>
                            </ul>
                            <p> <strong>3. (“Aggregated Data”)</strong> for internal purposes such as operating, maintaining, and improving the Security Services and distribution in general benchmarking or industry-related reports  </p>            
                            <p> <strong>4. Client Responsibilities.</strong>  You will use the Security Services (which, for clarity, include any Analytical Results as defined below), only: (a) for fraud detection and prevention purposes; (b) in accordance with the terms of this Agreement and the Provider’s Acceptable Use Policy; and (c) in compliance with applicable laws and any contractual or other obligation You have to any third party.  You are solely responsible for ensuring that its use of the Security Services, including its provision of the Customer Data, does not violate any Applicable Laws. Without prejudice to the generality of the foregoing,  You shall ensure it has the right to transfer or provide access to Customer Data to WorkX for the purposes contemplated in this Agreement (and where required, has obtained any necessary consents or authorizations to do so). The term <strong>“Analytical Results”</strong> means any proprietary data provided by Provider to You through the Security Services (excluding Customer Data), which shall include the results of Provider’s proprietary modeling and analysis of the Customer Data alone or in combination with other data in the Security Services, which results include a score for a particular action or event, substantiation for the score (i.e. the top data inputs that contributed to the particular score), and customer-configurable aggregated insights and reporting delivered through the Security Services.   </p>
                            <p> <strong>5. Data and Usage Restrictions.</strong>  You  shall not use the Security Services for any purposes not contemplated by this Agreement, including but not limited to purposes prohibited under the <strong>Fair Credit Reporting Act (FCRA) and the Equal Credit Opportunity Act (ECOA)</strong>, such as for background checks, as a factor in establishing an individual’s creditworthiness or eligibility for credit, insurance, housing, or employment, or in any way that facilitates discrimination, or in any other way that may violate or may cause You, WorkX or Provider to violate any applicable law. For clarity, the foregoing limitation applies to the Analytical Results and any other information derived from use of the Security Services, as well as the Security Services themselves  </p>
                            <p> <strong>6. Notice and Consent.</strong>  To the extent applicable, You will include on each service and digital application or property (e.g., webpages, apps, endpoints) from which You collect Customer Data a readily accessible privacy policy that includes accurate disclosures concerning the processing activities undertaken in connection with this Agreement that complies with applicable laws, including: (i) the collection and processing of Customer Data for fraud detection and prevention purposes; (ii) the disclosure of Customer Data to WorkX and Provider for fraud detection and prevention purposes (with a link to the Provider Privacy Notice); and (iii) if applicable, the use of snippets to collect and use Customer Data as described in this Agreement. For clarity, as between the parties, You are solely responsible for obtaining any necessary consents, permissions and approvals from and providing any notices to End Users required by applicable laws and this Agreement in connection with the activities described in this Agreement. The parties will provide reasonable assistance and reasonably cooperate with each other to assist with each party’s compliance with Applicable Laws and this Section</p>
                            <p> <strong>7. Updates to these Security Service Terms.</strong>   These Security Service Terms may be updated by WorkX from time to time in accordance with the terms set out in the WorkX Terms of Service.</p>
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
