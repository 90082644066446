import React, { useEffect, useState } from 'react';
import API from '../apis';
import MainLayout from '../components/MainLayout';
import { Button } from '@mui/material';
import 'react-phone-input-2/lib/style.css'
import CustomModal from '../components/CustomModal';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';

export default function Registration() {

    const [countryList, setCountryList] = useState([]);
    const [inputPhone, setInputPhone] = useState();
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [phoneInputObj, setPhoneInputObj] = useState({});
    const [input, setInput] = useState({
        emailAddress: '',
        phone: '',
        username: '',
        fullName: '',
        password: '',
        confirmPassword: '',
        orgName: '',
        orgUrl: ''
    });
    const [error, setError] = useState({
        emailAddress: '',
        phone: '',
        username: '',
        fullName: '',
        password: '',
        confirmPassword: '',
        orgName: '',
        orgUrl: ''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [regResponse, setRegResponse] = useState('');
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
        getCountryCodes();
    }, [])

    const getSelectedPackageId = () => {
        const location = useLocation();
        const packageData = location.state && location.state.packageData
        console.log('pckgId - ', packageData.pckgId);
        return packageData.pckgId;
    };
    const selectedPackageId = getSelectedPackageId();

    const getDeviceName = () => {
        return "";
    };

    const getBrowserName = () => {
        return "";
    };

    const getIpAddress = () => {
        return "";
    };

    const onInputChange = (e) => {
        const {name, value} = e.target;
        setInput((prev) => ({
            ...prev,
            [name]:value,
        }));
        validateInput(e);
    };

    const validateInput = (e) => {
        let {name, value} = e.target;
        setError((prev) => {
            const stateObj = {...prev, [name]: ''}
            switch(name) {
                case 'emailAddress': 
                    if(!value) {
                        stateObj[name] = 'Please enter Email Address.'
                    }
                    break;
                case 'phone': 
                    if(!value) {
                        stateObj[name] = 'Please enter Phone.'
                    }
                    break;
                case 'username': 
                    if(!value) {
                        stateObj[name] = 'Please enter User Name.'
                    } else if(value.length < 5) {
                        stateObj[name] = 'Username length must be in between 5 and 10.'
                    } else if(!((/^[a-zA-Z0-9]+$/).test(value))) {
                        stateObj[name] = 'Username must contain only alphanumeric characters.'
                    }
                    break;
                case 'fullName': 
                    if(!value) {
                        stateObj[name] = 'Please enter Full Name.'
                    }
                    break;
                case 'password': 
                    if(!value) {
                        stateObj[name] = 'Please enter Password.'
                    } else if(value.length < 8) {
                        stateObj[name] = 'Password length must be in between 8 and 20.'
                    } else if(!value.match("^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$")) {
                        stateObj[name] = 'Password must contain at least one letter, one number, one special character.'
                    } else if(input.confirmPassword && value !== input.confirmPassword) {
                        stateObj['confirmPassword'] = 'Password and Confirm Password does not match.'
                    } else {
                        stateObj['confirmPassword'] = input.confirmPassword ? '' : error.confirmPassword;
                    }
                    break;
                case 'confirmPassword':
                    if(!value) {
                        stateObj[name] = 'Please enter Confirm Password.'
                    } else if(input.password && value != input.password) {
                        stateObj[name] = 'Password and Confirm Password does not match.'
                    }
                    break;
                case 'orgName': 
                    if(!value) {
                        stateObj[name] = 'Please enter Account / Organization Name.'
                    }
                    break;
                case 'orgUrl': 
                    if(!value) {
                        stateObj[name] = 'Please enter Account / Organization URL.'
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        });
    };

    // const resend = new Resend('re_CTW37j8N_C4NtwgQVQvLa1fszgXVYUKsY');
    // async function sendEmailApi() {
    //     try {
    //       const { data, error } = await resend.emails.send({
    //         from: 'onboarding@resend.dev',
    //         to: ['ginnikhurana95@gmail.com'],
    //         subject: 'Hello world',
    //         // react: EmailTemplate({ firstName: 'Test User' }),
    //         html: '<p>Hi on sending your <strong>first email</strong>!</p>'
    //       });
      
    //       if (error) {
    //         return JSON.stringify({ error }, null, 2);
    //       }
      
    //       return JSON.stringify(data, null, 2);
    //     } catch (error) {
    //       return JSON.stringify({ error }, null, 2);
    //     }
    // }

    const getCountryListURL = "https://api.workxcrm.com/api/getCommonData/getCountryLst";
    const getCountryCodes = () => {
        try {
            fetch(getCountryListURL, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            }).then(response => response.json()).then(response => {
                if (response.result.response_Status == 'success') {
                    setCountryList(response.result.returnClass);
                    getCountryCodesList(response.result.returnClass);
                }
                else {
                    alert('Error - ', response.result.response_Message);
                }
            });
        }
        catch(error) {
            alert(error);
        }
    };

    const getCountryCodesList = (codeList) => {
        const newCodeList = codeList.map(function(item) {
            return item['countryIso2'].trim().toLowerCase();
        })

        let defaultCountry =  (newCodeList?.length>0 ? newCodeList[0] : 'us');
        let defaultCountryCode = (defaultCountry.countryIso2 ? 'us' : defaultCountry);
        setPhoneInputObj({
            "iso2List": [...newCodeList],
            "randomKey":Math.random(),
            "defaultCountryCode":defaultCountryCode
        });

        // setFilterCountryCodeList(newCodeList);
    };
    
    const getCountryObjBySelectedDomain = () => {
        return countryList.filter(e => e.countryIso2.trim().toLowerCase() == selectedCountryCode)[0].pk_countryId;
    }

    const memberSignUp = "https://api.workxcrm.com/api/usr_customerDir_Registration/MemberSignUp";
    const doMemberSignUpApi = async () => {
        try {
            const params = {
                "pk_UsrId": 0,
                "fk_PackagePriceId": selectedPackageId,
                "linkCode": "",
                "fk_UsrOrgDirId": 0,
                "userName": input.username,
                "fullName": input.fullName,
                "fk_genderId": 0,
                "mobileNo": inputPhone,
                "isMobileVerfied": false,
                "emailId": input.emailAddress,
                "isEmailVerified": false,
                "custPassword": input.password,
                "imageName": "",
                "fk_stateId": 0,
                "fk_countryId": getCountryObjBySelectedDomain(),
                "fk_usrRoleId": 0,
                "reference_userName": "",
                "affiliateUsrId": "",
                "registrationSource": "",
                "orgAccountName": input.orgName,
                "orgAccountURL": input.orgUrl,
                "deviceName": getDeviceName(),
                "browserName": getBrowserName(),
                "publicIpAddress": getIpAddress()
            }

            if (error['emailAddress'] != "" || 
                error['phone'] != "" || 
                error['username'] != "" || 
                error['fullName'] != "" || 
                error['password'] != "" || 
                error['confirmPassword'] != "" || 
                error['orgName'] != "" || 
                error['orgUrl'] != "" ) {
                    alert('fields not ok');
            } else {
                API.postRequestWithJsonForOnline(memberSignUp, params).then(res => {
                    console.log('apiResponseSuccess  - ', res);
                    setRegResponse(res);
                    setShowAlert(true);
                })
            }
        }
        catch(error) {
            console.log('error in catch - ', error);
            alert('Error', error);
        }
    };

    const displayAlertMessage = (response) => {
        if (response.status != 200) {

            var errorMsg = "";
            for (var key in response.errors) {
                errorMsg = errorMsg.concat("\n"+key + " :\n")
                response.errors[key].map(keyData => (
                    errorMsg = errorMsg.concat("\t\t"+keyData + " \n")
                ))
            }

            return errorMsg.split(/\n/).map(line => <React.Fragment key={line}>{line}<br/></React.Fragment>)
        } else {
            return response.result.response_Message;
        }
    }

    const handleAlertButton = () => {
        if (regResponse.response_Status === 'success') {
            clearForm();
            window.open("https://login.workxcrm.com/")
        } 
        setShowAlert(false);
    };

    const clearForm = () => {
        setInput({
            emailAddress: '',
            phone: '',
            username: '',
            fullName: '',
            password: '',
            confirmPassword: '',
            orgName: '',
            orgUrl: ''
        })
        setInputPhone('');
    };

    if (!isClient) {
        return null;
    };

    return(
        <>
            <MainLayout title={'Registration'}>
                {/* Start Checkout section */}
                <div className="checkout-page scroll-margin pt-120 pb-120" id="shop">
                    <div className="container">
                    <div className="row g-lg-4 gy-5">
                        <div className="col-lg-10">
                        <div className="checkout-form-wrapper">
                            <div className="checkout-form-title">
                            <h4>General Information</h4>
                            </div>
                            <div className="checkout-form">
                            {/* <form method="POST" onSubmit={() => doMemberSignUpApi()}> */}
                            <form id="registration-form" action="/api" method="POST">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Email Address*</label>
                                            <input 
                                                key={'email'}
                                                type="email" 
                                                placeholder="info@gmail.com" 
                                                name="emailAddress" 
                                                value={input.emailAddress}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.emailAddress && (
                                                <span className="err">{error.emailAddress}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Phone*</label>
                                            <PhoneInput
                                                country={"in"}
                                                value={inputPhone ?? ''}
                                                // onChange={onChange}
                                                // onBlur={onBlur}
                                                onlyCountries={phoneInputObj.iso2List}
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: false
                                                }}
                                                // inputClass={`${hasError ? "error" : ""}`}
                                                disableCountryCode={true}
                                                onChange={(phone, country) => {
                                                    setInputPhone(phone.replace(country.dialCode,'',));
                                                    setSelectedCountryCode(country.countryCode)
                                                    // setErrors({ ...errors, phone: false });
                                                }}
                                                containerClass="myPhoneInput"
                                                inputStyle={{
                                                    height: '50px',
                                                    border: '1px solid var(--border-color)',
                                                    borderRadius: '5px',
                                                    width: '100%',
                                                }}
                                                key={phoneInputObj.randomKey}
                                            />
                                            {error.phone && (
                                                <span className="err">{error.phone}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>User Name*</label>
                                            <input 
                                                key={'username'}
                                                type="text" 
                                                placeholder="User Name" 
                                                name="username" 
                                                minLength="5"
                                                maxLength="10"
                                                value={input.username}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.username && (
                                                <span className="err">{error.username}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Full Name*</label>
                                            <input 
                                                key={'fullName'}
                                                type="text" 
                                                placeholder="Full Name" 
                                                name="fullName" 
                                                value={input.fullName}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.fullName && (
                                                <span className="err">{error.fullName}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Password*</label>
                                            <input 
                                                key={'password'}
                                                type="password" 
                                                placeholder="**********" 
                                                name="password" 
                                                minLength="8"
                                                maxLength="20"
                                                value={input.password}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.password && (
                                                <span className="err">{error.password}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label id="confirmPassword">Confirm Password*</label>
                                            <input 
                                                key={'confirmPassword'}
                                                type="password" 
                                                placeholder="**********" 
                                                name="confirmPassword" 
                                                value={input.confirmPassword}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.confirmPassword && (
                                                <span className="err">{error.confirmPassword}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Account / Organization Name*</label>
                                            <input 
                                                key={'orgName'}
                                                type="text" 
                                                placeholder="Account / Organization Name" 
                                                name="orgName" 
                                                value={input.orgName}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.orgName && (
                                                <span className="err">{error.orgName}</span>
                                            )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-inner mb-30">
                                            <label>Domain / Organization URL*</label>
                                            <input 
                                                key={'orgUrl'}
                                                type="text" 
                                                placeholder="Domain / Organization URL" 
                                                name="orgUrl" 
                                                value={input.orgUrl}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                required/>
                                            {error.orgUrl && (
                                                <span className="err">{error.orgUrl}</span>
                                            )}
                                            </div>
                                        </div>
                                        {/* //    submit -> send email and redirect to - login.workxcrm.com */}
                                        <div className="col-lg-12">
                                            <div className="form-inner">
                                                <button
                                                    className="primary-btn1"
                                                    type="button"
                                                    data-text="Pay Now"
                                                    onClick={() => {
                                                        doMemberSignUpApi()
                                                        return false;
                                                    }}
                                                >
                                                    <span>Pay Now</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Checkout section */}

            </MainLayout>
            { showAlert && (
                <CustomModal isOpen={showAlert} onClose={() => setShowAlert(false)}>
                    <p>
                        {/* {displayAlertMessage(regResponse).split(/\n/).map(line => <React.Fragment key={line}>{line}<br/></React.Fragment>)} */}
                        {displayAlertMessage(regResponse)}
                    </p>
                
                    <Button
                        variant="flat" 
                        color="warning" 
                        style={{
                            alignSelf: 'end'
                        }}
                        onPress={() => handleAlertButton()}
                    >
                        {'ok'}
                        {/* {regResponse.result.response_Status === 'success' ? 'continue' : 'ok'} */}
                    </Button>
                </CustomModal>
            )}
        </>
    );
}
