import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function GdprPrivacy() {

    return(
        <>
            <MainLayout title={'GDPR Privacy Notice'}>
                <section id="gdpr-section">
                    <div className="container margin-top-container">
                        <p>This Privacy Notice for European Economic Area (<strong>“EEA”</strong>) Residents (<strong>“GDPR Privacy Notice”</strong>) supplements the information contained in our Privacy Policy located at WorkX.com/privacy-policy/ and applies to all visitors, users, and others who reside in the European Economic Area who use our Website, App or Service (each as defined and described in the Privacy Policy).  Awapal Solutions Private Limited d/b/a WorkX (<strong>“WorkX”, “we”, “us”, or “our”</strong>) has adopted this GDPR Privacy Notice to comply with the General Data Protection Regulation (<strong>“GDPR”</strong>) and EU data protection law more generally.  The GDPR provides certain rights to EEA residents including the right to request access to, obtain, correct, amend, delete, or limit the use of your personal data.  Any terms defined in the GDPR have the same meaning when used in this GDPR Privacy Notice.  With respect to users who are EEA residents, this notice is incorporated and made a part of our Privacy Policy, and the terms of this GDPR Privacy Notice shall control with respect to EEA residents in the event of any inconsistency between our Privacy Policy and this GDPR Privacy Notice.  Where we use capitalized terms in this GDPR Privacy Notice that are not expressly defined, those terms have the meaning provided in the Privacy Policy.</p>
                        <p>Please see the Privacy Policy for a general account of the information we collect, how we use and share it.  In addition, the GDPR sets out a number of different reasons for which we may collect and process your personal data in certain circumstances. Where the GDPR applies, the legal bases we rely upon include:
                        </p>
                        <ul className="wp-block-list" style={{listStyle:"dash"}} >
                            <li><strong>Consent</strong>: We can collect and process your data with your consent, where required by the GDPR. We will explain how we intend to use your personal information where we ask for your consent and will only use it for that purpose. For example, if you have given your consent to receiving marketing material from us then we will provide you that marketing material. You can withdraw your consent at any time.
                            </li>
                            <li> <strong>Contractual obligations:</strong>  We need your personal data to comply with our contractual obligations. For example, where you have provided us with your email address to receive access to our Services, we will use this information in order to effectively deliver and communicate information to you in regard to such products and services.</li>
                            <li> <strong>Compliance with legal obligations:</strong> If the law requires us to, we may need to collect and process your data for legal compliance reasons. For example, we can pass on details of people involved in fraud or other criminal activity. </li>
                            <li> <strong>Legitimate interest:</strong> We may require your data to pursue our legitimate interests or those of a third party in a way which might reasonably be expected as part of running our business and which does not materially impact your rights, freedom or interests. For example, it is in our legitimate interests to </li>
                            <li>(a) ensure that content from our Website are presented in the most effective manner for you and your computer and that we provide you with the information, products and services that you request from us,</li>
                            <li>(b) to keep our Site, App and our Service safe and secure;</li>
                            <li>(c) for measuring or understanding the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you (including individual and pseudonymous analysis and profiling);</li>
                            <li>(d) to allow you to participate in interactive features of our Services (if applicable), and</li>
                            <li>(e) to use marketing related information about you that we have lawfully collected.</li>

                        </ul>                
                        <p> All Personal Information (including the Personal Information of EEA residents) hosted on our behalf may be transferred and stored by our third party service providers at destinations outside the EEA that may not be subject to equivalent data protection law. For a list of the sub-processors that we use, please see www.workxcrm.com/legal/subprocessors. Our business needs may change from time to time and so we will periodically update this page to provide notice of additions and removals to our list of sub processors. Where we engage sub-processors, we will endeavour to impose data protection terms that provide at least the same level of protection for Personal Information as those in our Privacy Policy, to the extent applicable to the nature of the services provided by such sub-processors. It may also be processed by staff situated outside the EEA who work for us or for one of our suppliers.  We may transfer your personal data outside the EEA:   </p>
                        <ul className="wp-block-list" style={{listStyle:"dash"}} >
                            <li>in order to host or store it;</li>
                            <li>in order to enable us to provide goods or services to and fulfil our contract with you or the company your work for;
                            </li>
                            <li>where we are legally required to do so;</li>
                            <li>in order to facilitate the operation of our group of businesses, where it is in our legitimate interests and we have concluded these are not overridden by your rights.
                            </li>
                            <li>Where your information is transferred outside the EEA, we take steps reasonably necessary to ensure that your data is subject to appropriate safeguards, such as where applicable relying on a recognised legal adequacy mechanism and contracts, to help ensure your rights and protections travel with your data and that your personal data is treated securely and in accordance with this Policy.
                            </li>                            
                        </ul>
                        <p>If you are an EEA Citizen, you have the following additional rights under certain circumstances:</p>
                        <ul className="wp-block-list" style={{listStyle:"dash"}}  >
                            <li>to be provided with a copy of your personal data held by us;</li>
                            <li>to request the rectification or erasure of your personal data held by us;
                            </li>
                            <li> request that we restrict the processing of your personal data (for example, while we verify or investigate your concerns about your information);
                            </li>
                            <li>to object to the further processing of your personal data, including the right to object to marketing; and
                            </li>
                            <li>to request that your personal data be moved to a third party.</li>
                        </ul>
                        <p>In order to exercise these rights, you may contact us using the contact details set out below. We may ask for further information to clarify your identity and location. If you are a customer or employee of one of our service provider customers, please contact the service provider that you interact with directly., as in that circumstance we will be acting as a data processor, not a data controller.</p>
                        <p><strong>Changes to this Notice</strong></p>
                        <p>We reserve the right to amend this GDPR Privacy Notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on our website and update this notice’s effective date.  If as a business we intend to use your personal information for a purpose that was not previously disclosed in the privacy policy or in the notice at collection, we shall directly notify you of this new use and obtain explicit consent from the consumer to use it for this new purpose.</p>
                        <p><strong>Contact Information</strong></p>
                        <p>If you have any questions or comments about this notice, the ways in which we collect and use your information described in the GPDR Privacy Notice and in the Privacy Policy, your choices and rights regarding such use, or wish to exercise your rights under European data protection law, please do not hesitate to contact us as follows:</p>
                        <ul className="wp-block-list">
                            <li>Email us at <a href="mailto:legal@workxcrm.com">legal@workxcrm.com</a></li>
                            <li>Call our customer services team at <a href="tel: +9999952777" >+91-99999-52-777</a> </li>
                        </ul>
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
