import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function Security() {

    return(
        <>
            <MainLayout title={'WorkXCRM Security: Protecting Your Data & Privacy'}>
                <section>
                    <div className="container margin-top-container">
                            <p>Security and privacy are at the core of WorkX’s culture. Thousands of small and medium businesses trust us to keep sensitive data about their employees, customers, and business safe. Internally, we make sure that WorkX employees can’t access your information unless it’s absolutely necessary, and we’re always reviewing our access levels to make sure it stays that way. To keep your information safe from external threats, we have these measures in place:
                            </p>

                            <div>
                                <h6>ENCRYPTION</h6>
                            <p>
                                We only use secure connections, so information is always encrypted using Transport Layer Security (TLS) when transmitted from your mobile devices and computers to our systems.
                            </p>
                                </div>

                            <div>
                                <h6>DATA STORAGE</h6>
                                    <p>
                                    Your data is stored by AWS, which is certified for their world-class security, including their physical security, datacenter operations, and personnel security.
                                    </p>
                            </div> 

                            <div>
                                <h6>SECURITY TESTING</h6>
                                    <p>
                                    We hire external security firms to test our security at least annually, and use automated tools to manage vulnerabilities and protect our network.
                                    </p>
                            </div> 

                            <div>
                                <h6>CREDIT CARD SECURITY</h6>
                                    <p>
                                    Whether you’re paying for your WorkX account with your card, or taking payments from your customers in WorkX, your credit card information is always secure. We use trusted third parties to handle payment processing: Recurly for your subscription payments, and Stripe for customer payments through WorkX.
                                    </p>
                            </div>
                            <div>
                                <p>If you have any questions, reach out to our security team at <a href="mailto:legal@workxcrm.com">legal@workxcrm.com</a></p>    
                            </div> 
                    </div>
                </section>
            </MainLayout>
        </>
    );
}
