import React from 'react';
import MainLayout from '../../components/MainLayout';

export default function PaymentServiceFees() {

    return(
        <>
            <MainLayout title={'Payment Service Fees'}>
                <section id="payment-service-fees-section">
                    
                </section>
            </MainLayout>
        </>
    );
}
