import React from 'react';
import MainLayout from '../../components/MainLayout';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy() {

    return(
        <>
            <MainLayout title={'WorkX Privacy & Cookie Policies | Data Storage Guidelines'}>
                <section>
                    <div className="container margin-top-container">
                        <p>Awapal Solutions Private Limited d/b/a WorkX (“<strong>WorkX</strong>”/“<strong>we</strong>”/“<strong>us</strong>”/“<strong>our</strong>”) owns and operates the website located at <a href="https://workxcrm.com/">https://workxcrm.com</a> (the “<strong>Website</strong>”), and any subdomains used in connection with or related to the same, and the WorkX mobile application (the “<strong>App</strong>”). WorkX is committed to protecting the personal information that we collect, use, and disclose in the course of providing you with WorkX’s Service.. This Privacy Policy describes how we collect, store, use and distribute information about identifiable individuals (“<strong>Personal Information</strong>”) through the Website, App and Service (as defined in our <Link legacyBehavior to="/legal/terms-of-service"><a>Terms of Service</a></Link>). Our Service is provided to business customers who have registered for an account with us (“<strong>Account Owners</strong>”) and any information collected via the Website, App, and Service from or about Account Owners’ employees, their own customers (“<strong>End Users</strong>”), and contractors will be made available to the applicable Account Owner and their authorized Employee Users (as defined in our <Link legacyBehavior to="/legal/terms-of-service"><a>Terms of Service</a></Link>) and will be used by such Account Owner according to their own privacy policy(ies).</p>
                        <p><strong>A Note about Children.</strong> You must be at least 18 years old to use the Website, App, and/or Service. We do not intentionally gather Personal Information from visitors who are under the age of 13. If a child under 13 submits Personal Information to WorkX and we learn that the Personal Information is the information of a child under 13, we will attempt to delete the information as soon as possible. If you believe that we might have any Personal Information from a child under 13, please contact us at privacy@workxcrmr.com.</p>
                        <p><strong>A Note to Users Outside of the United States and Canada.</strong> If you are a non-U.S. or non-Canadian user of the Website, App, and/or Service, by visiting or using the Website, App, and/or Service and providing us with data, you acknowledge and agree that your Personal Information may be processed for the purposes identified in this Privacy Policy. In addition, your Personal Information may be processed in the country in which it was collected and in other countries, including the United States and Canada, where laws regarding processing of Personal Information may be less stringent than the laws in your country. By providing your data, you consent to such transfer. If you are in the EEA please see the <Link legacyBehavior to="/legal/gdpr"><a>GDPR Privacy Notice.</a></Link> </p>
                        <p><strong>Lawful Processing: We process your Personal Information as set out in this Privacy Policy and to the extent necessary for the performance of our obligations to you, which may include providing the Service to you under our Terms of Service at </strong><Link legacyBehavior to="/legal/terms-of-service"><a><strong>www.workxcrm.com/terms-of-service</strong></a></Link><strong>. We set out below the type of Personal Information that we collect and how we use it.</strong> Except as set forth in this Privacy Policy, your Personal Information will not be used for any other purpose without your consent. We will not actively collect Personal Information for the purpose of sale or third-party marketing in a way that specifically identifies the individual; we do not sell customer lists. You may withdraw your consent to our processing of your Personal Information at any time. However, withdrawing consent may result in your inability to continue using the Website, the App, and/or the Service. Certain information may be exempt from such a request, such as information we require for compliance with a legal or regulatory requirement or other business purpose.&nbsp;</p>
                        <p><strong>Scope</strong>. This Privacy Policy covers the activities of WorkX in the course of providing the Service and operating the Website and App. It does not apply to the privacy practices of third-parties that we do not own or control, including Account Owners and third-parties that offer products or services to Account Owners linked or made available through our Service (e.g., via the WorkX Marketplace). Account Owners are responsible for ensuring that they have obtained the necessary authorizations and consents for any Personal Information they make available to WorkX for use in accordance with this Privacy Policy. In particular, Account Owners represent and warrant to WorkX that they have the necessary rights under applicable law or have obtained the necessary consents from each End User whose Personal Information is provided by that Account Owner to WorkX in order to allow WorkX to use, disclose, and otherwise process such Personal Information as&nbsp; described in this Privacy Policy.</p>
                        <p><strong>Personal Information about End Users</strong>. We may process Personal Information about End Users, such as their name, phone number, address, and payment information in connection with transactions processed or data stored by Account Owners through our Service. We process this information in accordance with our agreements with the Account Owners as a service provider, or data processor, on the Account Owners’ behalf. Should an End User have any questions or requests to exercise privacy rights, said End User should contact the Account Owner with whom the End User was dealing. For clarity, the term End User, refers to a customer of an Account Owner. For example, if an individual hired a service professional who uses WorkX’s Service (i.e., an Account Owner), then that individual would be an End User.&nbsp;&nbsp;</p>
                        <p><strong>What We Collect.</strong> Set out below are the ways in which we may collect, use, and disclose Personal Information:</p>
                        <ul className="wp-block-list">
                            <li><strong>Personal Information We Collect from Account Owners.</strong> We may collect registration data such as your full name and email address as well as business contact information of individuals who work for Account Owners (i.e., field service companies that use the Service to manage aspects of their business and transactions) in order to communicate with those Account Owners about their business relationship with WorkX. We may also collect payment credentials or related information from Account Owners in order to allow those Account Owners to pay WorkX for services procured by such Account Owners or in order to remit to such Account Owners their share of transaction fees collected from End Users. You have the ability to take and upload photos, notes and other content to the Website, App, and/or Service such as a photo and description of a job that you have completed. The content that you make available will be accessible to the Account Owner and their authorized Employee Users.</li>
                            <li><strong>E-commerce Transactions. </strong>If you make a purchase through your WorkX account, WorkX will collect your name, credit card billing information and shipping information. This information is used to process your transaction and deliver the products purchased.</li>
                            <li><strong>Logs. </strong>Our servers automatically record information created by your use of our Service to help us diagnose and fix technical issues, and to improve the overall quality and user experience of our Service. Logs may include information such as your IP address, browser type, operating system, details of how you used our Service (such as the functions you asked our Service to perform), diagnostic information related to the Service (such as crash or activity reports), the referring web page, pages visited, location, your mobile carrier, device and application IDs, search terms, and cookie information. Please see below for more information on how we use cookies and other tracking technologies.</li>
                            <li><strong>Cookies and Similar Tracking Technologies.</strong> We use cookies, tracking pixels, and similar tracking technologies to gather information about how you are interacting with the Service, which may include identifying your IP address, browser type, and referring page. Please see the section titled “Cookies &amp; Other Automatic Data Collection and Tracking Methods<strong>” </strong>below for more information.</li>
                            <li><strong>Employee and Job Applicant Information.</strong> When we seek candidates for potential jobs or contracting engagements with WorkX, or if you apply for a job with us, we collect Personal Information to process and respond to that application or inquiry, which may include information contained in a resume, cover letter, or other related materials. With your consent, we may also obtain Personal Information from third-party job references or former employers, or conduct background checks. For people who become our employees or contractors, we will typically retain the information provided by those candidates in the application process along with additional information to manage their employment or contractor relationship with us, including, without limitation, information related to income tax reporting and withholding, and enrollment in WorkX benefit plans (in each case, to the extent applicable for the relevant relationship).</li>
                            <li><strong>Marketing Information.</strong> From time to time, we may conduct surveys or hold contests or other events and in connection with such surveys, contests, or events, we may use your connected WorkX account information to identify you and we may collect information you elect to provide about yourself, such as your name, email address, telephone number, organization name and address, and general information about the company for whom you work. In addition, we may use third-party service providers to collect business-related information about your employer such as its name, size, and revenue in connection with potentially offering the Service to your employer or for analytics purposes, which may include evaluating current or future service offerings.</li>
                            <li><strong>WorkX Suppliers and Partners. </strong>WorkX collects business contact information of individuals who work for our suppliers and other partners to communicate with those suppliers and partners about their business relationship with WorkX.</li>
                        </ul>
                        <p><strong>Use of Personal Information</strong>. WorkX uses the Personal Information described above to:</p>
                        <ul className="wp-block-list">
                            <li>provide, operate, maintain, and improve the Website, App, and Service;</li>
                            <li>facilitate account administration, billing, verification, and authentication and to contact you in relation to the same;</li>
                            <li>send technical notices, updates, security alerts, and support and administrative messages;</li>
                            <li>facilitate transactions and send related information to the relevant transaction participants, including confirmations and invoices;</li>
                            <li>respond to comments, questions, and requests, and provide customer service and support;</li>
                            <li>communicate with you and provide news or information about us;</li>
                            <li>investigate, identify, mitigate, and prevent potential or actual fraud, unauthorized access to the Website, App, and/or Service, and other illegal activities;</li>
                            <li>monitor and analyze trends, usage, and activities in connection with the Website, App, and/or Service, including generating aggregated and anonymized statistics and disclosing industry trends to third-parties which are derived from use of the Service but do not include any Personal Information;</li>
                            <li>conduct business and contractual relationships that we have with various persons and companies (such as customers, suppliers, partners, and employees);&nbsp;</li>
                            <li>where we have your consent or there is an existing relationship between you and a third-party, we may recommend features, products, and services that might be of interest to you, and share your information to allow our third-party service providers to make recommendations on products as well;&nbsp;</li>
                            <li>facilitate the use of AI Tools (as defined in our Terms of Service) and for analysis, improvement, and security purposes related to the same and, to the extent that WorkX engages third-party service providers in connection with the AI Tools, you acknowledge, authorize, and agree, as applicable, that said third-parties may access, use, and store any information in connection with the AI Tools in accordance with their applicable terms and policies; and&nbsp;</li>
                            <li>fulfill other purposes which we will notify you about and for which we shall seek your consent.</li>
                        </ul>
                        <p><strong>THIRD-PARTY RELATED-TERMS</strong></p>
                        <ul className="wp-block-list">
                            <li><strong>Connecting through Third-Party Sites.</strong> Users may login or register to use the Service through certain third-party owned or operated social networking services (“<strong>SNSs</strong>”). If you are already logged into the App and/or Service and a SNS or a Third-Party Account, when you connect to the SNS or Third-Party Account, you may be prompted to merge your profiles. By proceeding, you are allowing the App and/or Service to access your information and you are agreeing to the SNSs’ or Third-Party Account’s terms of use in your use of the App or Service. Conversely, if you are not currently registered as a user of the App or Service, and you click on “Sign in” through the SNS or Third-Party Account that we support, you will first be asked to enter your SNS or Third-Party Account credentials and then be given the option to register for the App or Service. In this case, we may receive information from the SNS or Third-Party Account to make it easier for you to create an account on the Website and show our relevant content from your SNS friends. Once you register on the Website and connect with the SNS or Third-Party Account you will be able to automatically post recent activity back to the SNS or Third-Party Account. Any information that we collect from your SNS account or Third-Party Account may depend on the privacy settings you have with that SNS or Third-Party Account, so please consult the SNSs’ or Third-Party Account’s privacy and data practices. If you decide at any time that you no longer wish to have your Third-Party Account linked to your account, please contact us at legal@workxcrm.com.</li>
                            <li><strong>Third-Party Links.</strong> The Website, App, and/or Service may contain links to other third-party websites that are not owned or controlled by us. Such third-party websites are governed by the terms and conditions and privacy policies of such third-party providers and we are not involved in any interaction or transaction between you and such third-parties. We encourage you to read the privacy policies of every website you visit. The links to third-party websites or locations are for your convenience and do not signify our endorsement of such third-parties or their products, content, or websites.</li>
                            <li><strong>Third-Party Partner Integrations.</strong> Optional third-party add-ons may be offered by our integration partners from time to time. Data shared with third-party integration partners is subject to the privacy policies and terms of service of such third-parties.</li>
                            <li><strong>WorkX Payments.</strong> If you register for, or otherwise use, the WorkX Payments service, we may collect additional information from you, including certain account information. We will also collect certain payment and other personal information from End Users.
                                <ul className="wp-block-list">
                                    <li>Because our payment service is provided by a third-party service provider, Stripe, Inc. (“<strong>Stripe</strong>”) <strong>by signing up for and/or using WorkX Payments, you are also agreeing to the terms of Stripe’s privacy policy</strong> (see further <a href="https://www.stripe.com/ca/privacy">www.stripe.com/ca/privacy</a>). Any personal information that you provide to Stripe about you or End Users will be treated in accordance with the terms of Stripe’s privacy policy.</li>
                                    <li>We may conduct background checks on accounts using the WorkX Payments Service through a service provider, LexisNexis. To conduct these background checks, we will share certain business and personal information associated with your account with LexisNexis. For more information about how LexisNexis handles this information, please refer to LexisNexis’s <a href="https://can01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frisk.lexisnexis.com%2Fgroup%2Fprivacy-policy&amp;data=05%7C01%7CJSalloum%40osler.com%7C36c52cfe61014dbc5dae08da768f7fb5%7C38b8d7e73b2745709e91cf2ab620b2cd%7C1%7C0%7C637952655995507458%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&amp;sdata=ScpIA9QEYnm%2BRthE862cv84DSMxgJMrKIf6s924rzxw%3D&amp;reserved=0">Privacy Policy</a> and <a href="https://can01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frisk.lexisnexis.com%2Fgroup%2Fprocessing-notices&amp;data=05%7C01%7CJSalloum%40osler.com%7C36c52cfe61014dbc5dae08da768f7fb5%7C38b8d7e73b2745709e91cf2ab620b2cd%7C1%7C0%7C637952655995507458%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&amp;sdata=4qj6eaLY0yPOUV9QhU18%2BV6xu%2BwsUZ3TwVL8tQnN4Q0%3D&amp;reserved=0">Processing Notice</a>.</li>
                                    <li>If you choose to connect your bank account directly by providing the access information to such third-party account, our service provider, Plaid Inc. (“<strong>Plaid</strong>”), will access such third-party accounts. By providing the required access information, you grant us and Plaid permission and authority to access the applicable third-party accounts and retrieve, gather, and store relevant information including banking information such as, for example, your banking institution name, account types, and account balance. You are not permitted to provide WorkX with any access information for any third-party accounts for which you are not the principal account holder unless you have the authority to act on behalf of the principal account holder. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the <a href="https://plaid.com/legal/#end-user-privacy-policy">Plaid Privacy Policy</a>.‍</li>
                                </ul>
                            </li>
                            <li><strong>WorkX Marketplace. </strong>The Website, App, or Service may include access to a marketplace enabling subscribing WorkX users to browse and select third-party software applications, tools, and add-ons (collectively “<strong>Third-Party Apps</strong>”) which are offered by third-party developers (“<strong>App Providers</strong>”). By downloading any Third-Party App you acknowledge and agree that the App Provider will have access to certain information from you when contracting with you to provide you with access to one or more Third-Party Apps. Use of any personal information provided directly or indirectly to or derived by an App Provider will be subject to the privacy policy of the applicable App Provider. WorkX has no control over the privacy policies of App Providers although we contractually require App Providers to agree to: (i) comply with all applicable laws and regulations in their collection and processing of any personal data in accordance with their stated privacy policies; (ii) inform users of a Third-Party App (“<strong>Users</strong>”) about any information about a User or data generated or derived from a User’s use of a Third-Party App (“<strong>User Data</strong>”) that the Third-Party App collects and how such User Data is used, stored, secured, and disclosed, and their data retention policies; and (iii) describe the controls that Users have over the use and sharing of their User Data and how they may access their User Data.&nbsp;&nbsp;</li>
                        </ul>
                        <p>If we plan to use your Personal Information in the future for any other purposes not identified above, we will only do so after informing you by updating this Privacy Policy. See further the section of this Privacy Policy entitled ‘Amendment of this Policy’.</p>
                        <p><strong>COOKIES &amp; OTHER AUTOMATIC DATA COLLECTION AND TRACKING METHODS</strong></p>
                        <ul className="wp-block-list">
                            <li><strong>Location Data Generally.</strong> If you download the App, the App will request access to your device location. If you grant the App access to your device location, we will be able to track the physical location of your device using GPS and WiFi signals. We use this information to provide the Service. You can remove the App’s access to your device location at any time by changing your privacy settings on your device. Not enabling access to your device location may limit the features and functionality of the Service that you can use. Location data will be available to the Account Owner, account administrators, and to WorkX.</li>
                            <li><strong>Location Data: Security Tracking. </strong>Location data is automatically tracked for fraud prevention purposes (e.g., to potentially identify unauthorized access to your account) and unusual patterns or location data that is flagged by the Security Services (defined in Third-Party Services below) may be notified to the Account Owner/applicable administrators. In the event of high-risk activity flagged by the Security Services, your account may be temporarily disabled to protect against fraudulent activity through your account. We will notify you if we take this action and will provide information on how to verify your account and unblock access to the same.</li>
                            <li><strong>Time Tracking.</strong> You may use the time tracking functionality in the App to track how long it takes you to complete a work task. Time tracking functions may also use location data, if enabled. Such information will be made available to the Account Owner, account administrators, and to WorkX in our logs.</li>
                            <li><strong>Metadata.</strong> We collect and use various metadata derived from your use of the Service. For example, if you take a photo using the App, we also collect the time that such photo was taken. Such metadata will also be made available to the Account Owner and their authorized Employee Users.</li>
                            <li><strong>Third-Party Services.</strong> We receive information from third-parties who help us provide the Service, including the following:
                                <ul className="wp-block-list">
                                    <li><strong>Cookies and Tracking:</strong> In addition to the tracking technologies we place, other companies may set their own cookies or similar tools when you visit our Website or use the App and/or Service. The information generated by the cookies or other tracking technologies related to our Website and Service (the “<strong>Analytics Information</strong>”) is transmitted to the Analytics Services and Security Services.&nbsp;</li>
                                    <li><strong>Analytics</strong>: We engage third-party analytics services, including but not limited to Google Analytics (“<strong>Analytics Services</strong>”), to help analyze how you use the Website and Service. The Analytics Services use Analytics Information to compile reports on user activity. We may receive reports based on these parties’ use of these tools on an individual or aggregate basis. We use the information we get from Analytics Services only to improve our Website and Service. The Analytics Services may also transfer information to third-parties where required to do so by law, or where such third-parties process Analytics Information on their behalf. Each Analytics Services’ ability to use and share Analytics Information is restricted by such Analytics Services’ terms of use and privacy policy. By using our Website, App, and/or Service, you consent to the processing of data about you by Analytics Services in the manner and for the purposes set out above. Analytics Services are included in our subprocessor list available at: <a href="/legal/subprocessors">https://workxcrm.com/legal/subprocessors</a> (the “<strong>Sub-Processors</strong>”).</li>
                                    <li><strong>Security and Fraud: </strong>We engage third-parties that deliver content or offers and third-party identity verification, and fraud detection and prevention services (“<strong>Security Services</strong>”) to help predict and prevent fraudulent activities on the Website and Service. We may receive reports based on these parties’ use of these tools on an individual or aggregate basis.</li>
                                </ul>
                            </li>
                            <li><strong>System Logs, Cookies and Similar Tracking Technologies.</strong> Cookies and similar tracking technologies are used by us to track content usage and traffic on the Website and App. A cookie is a text file that is downloaded on your hard disk by a web server when you visit certain websites. Cookies and similar tracking technology help us compile aggregate statistics about usage of the Website and App, such as how many users visit the Website, how long users spend viewing the Website, and what pages are viewed most often. This information is used to improve the content of the Website and App. You can set your browser to notify you when you are sent a cookie. This gives you the chance to decide whether or not to accept it. If you disable cookies, you may not be able to take advantage of all the features of the Website, App, and/or Service. Your IP address is reported by your web browser whenever you visit a page on the Website. This information is recorded together with your registration information on our databases.</li>
                        </ul>
                        <p><strong>How We Respond to Do Not Track Signals</strong>. We do not currently respond to “do not track” signals or other mechanisms that might enable consumers to opt out of tracking on our Website.</p>
                        <p><strong>DISCLOSURE OF PERSONAL INFORMATION WITH THIRD-PARTIES</strong></p>
                        <p><strong>Sub-Processors. </strong>We work with third-party sub-processors for cloud hosting, data storage, customer relationship tools, data analysis, payment processing, and fraud or risk prevention, among other things, to provide you with the Service. For more information, please see our list of <Link legacyBehavior to="/legal/subprocessors"><a>Sub-Processors</a></Link>.</p>
                        <p><strong>Business Transfers.</strong> If our business (or substantially all of our assets) are acquired by a third-party, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information may be made available or otherwise transferred to the new controlling entity, where permitted under applicable law. Your Personal Information may also be transferred in connection with due diligence for any such transactions. In all cases, if any such transactions occur, your Personal Information will remain subject to the restrictions and protections set forth in this Privacy Policy.</p>
                        <p><strong>SNSs. </strong>Our Website, App, and/or Service may enable you to post content to SNSs or other Third-Party Accounts. If you choose to do this, we will provide information to such SNSs or Third-Party Accounts in accordance with your elections. You acknowledge and agree that you are solely responsible for your use of those websites and that it is your responsibility to review the terms of use and privacy policy of the third-party provider of such SNSs or Third-Party Accounts. We will not be responsible or liable for: (i) the availability or accuracy of such SNSs or Third-Party Accounts; (ii) the content, products, or services on or availability of such SNSs; or (iii) your use of any such SNSs or Third-Party Accounts.</p>
                        <p><strong>App Providers. </strong>By accessing Third-Party Apps or WorkX-Built Apps through the WorkX Marketplace, or through other means as applicable, certain Personal Information may be shared by us with App Providers in the case of Third-Party Apps, or applicable third-party software providers in the case of WorkX-Built Apps.</p>
                        <p><strong>Payment Processors.</strong> When you provide credit card or other payment information to pay for WorkX subscriptions, your credit card and financial information is processed by a third-party subscription management provider. WorkX does not store and does not have access to detailed credit card information. If you use WorkX Payments, as further described in our Terms of Service, End User’s payment information and payments will be processed by Stripe and such information and processing will be subject to Stripe’s own legal agreements which you should read carefully.</p>
                        <p><strong>As Required by Law.</strong> We may disclose your Personal Information to third-parties without your consent if we have reason to believe that disclosing this information is necessary to identify, contact, or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other users, or anyone else (including the rights or property of anyone else) that could be harmed by such activities. Further, we may disclose Personal Information when we believe in good faith that such disclosure is required by and in accordance with the law. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:</p>
                        <ul className="wp-block-list">
                            <li>satisfy any applicable law, regulation, legal process, or governmental request (including in pursuant to subpoenas, civil investigative demands, or similar processes);&nbsp;</li>
                            <li>enforce our contracts or user agreements, including investigation of potential violations hereof; and</li>
                            <li>detect, prevent, or otherwise address fraud, security, or technical issues.&nbsp;</li>
                        </ul>
                        <p>The above may include exchanging information with other companies and organizations for fraud protection, spam/malware prevention, and know-your-customer purposes. Notwithstanding the general terms of this policy, the collection, use, and disclosure of Personal Information may be made outside of the terms of this Privacy Policy to the extent provided for in any applicable privacy or other legislation in effect from time to time, or pursuant to court orders (including in respect to depositions, interrogatories, subpoenas, civil investigative demands, and other court or regulatory-mandated discovery processes).</p>
                        <p><strong>OTHER RIGHTS AND RESPONSIBILITIES</strong></p>
                        <p><strong>Retention.</strong> We will keep your Personal Information for as long as it remains necessary for the identified purpose for which we have collected it or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally identifiable data, account recovery, or to comply with our legal obligations or resolve disputes. All retained personal information will remain subject to the terms of this Privacy Policy. If you request that your Personal Information be removed from our systems, it may not be possible to completely delete all your Personal Information for the reasons mentioned above.</p>
                        <p><strong>Access &amp; Accuracy. </strong>You have the right to access the Personal Information we hold about you in order to verify the Personal Information we have collected in respect to you and to have a general account of our uses of that information. Upon receipt of your written request, we will provide you with a copy of your Personal Information although, in certain limited circumstances, we may not be able to make all relevant information available to you such as where that information also pertains to another user. In such circumstances we will provide reasons for the denial to you upon request. We will endeavor to deal with all requests for access and modifications in a timely manner. We will make every reasonable effort to keep your Personal Information accurate and up-to-date, and we will provide you with mechanisms to update, correct, delete, or add to your Personal Information as appropriate. As appropriate, this amended Personal Information will be transmitted to those parties to which we are permitted to disclose your information. Having accurate Personal Information about you enables us to give you the best possible service.</p>
                        <p><strong>Residents of the European Economic Area (“EEA”). </strong>Please go to <Link legacyBehavior to="/legal/gdpr" ><a>https://www.workxcrm.com/legal/GDPR</a></Link> to read additional terms which apply to EEA residents’ data under the General Data Protection Regulation.&nbsp;</p>
                        <p><strong>California Consumer Privacy Act. </strong>Please go to read additional disclosures required under the California Consumer Privacy Act.</p>
                        <p><strong>Amendment of this Policy.</strong> We reserve the right to change this Privacy Policy at any time. If we decide to make any material changes to this Privacy Policy in the future, including how we use your Personal Information, we will send you an email or in-App notification. Unless stated otherwise, our current Privacy Policy applies to all Personal Information that we have about you. The date on which the latest update was made is indicated at the top of this document. Your continued use of the Website, App, and/or Service signifies your acceptance of any changes. If you do not agree to any changes after receiving a notice of such changes or otherwise do not agree with this Privacy Policy, you shall stop using the Website, the App, and/or the Service. You should consult this Privacy Policy regularly for any changes.</p>
                        <p><strong>Contact Us.</strong> If you would like to access your information or would otherwise like to exercise any of your rights in respect of your Personal Information (whether as set out above or otherwise), if you have any questions, comments, or suggestions, or if you find any errors in our information about you, please contact us at: legal@workxcrm.com</p>
                        <p>Attn: Privacy Officer<br/>B-4 First Floor, Sector 63 Noida<br/>U.P 201301, India</p>
                    </div>                      
                </section>
            </MainLayout>
        </>
    );
}
